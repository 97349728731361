import React, { useEffect, useState } from "react";
import "./Header.scss";
import Puma from "../assets/Puma.png";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  Popover,
  List,
  ListItem,
  Divider,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import accountCircleIcon from "../assets/account_circle.svg";
import logoutIcon from "../assets/logout.svg";
import settingsIcon from "../assets/settings.svg";
import shieldLockIcon from "../assets/shield_lock.svg";
import Notification from "../assets/NotificationBell.svg";
import Close from "../assets/Close.svg";
import { useDispatch, useSelector } from "react-redux";
import { logoutUser } from "../../actions/userAction";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

// import { styled } from '@mui/system';
// import { Tabs } from '@mui/base/Tabs';
// import { TabsList as BaseTabsList } from '@mui/base/TabsList';
// import { TabPanel as BaseTabPanel } from '@mui/base/TabPanel';
// import { buttonClasses } from '@mui/base/Button';
// import { Tab as BaseTab, tabClasses } from '@mui/base/Tab';

function Header() {
  const [ProfilePopUp, setProfilePopUp] = useState(null);
  const [NotificationPopup, setNotificationPopup] = useState(false);
  const [opendialog, setOpendialog] = React.useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [pageTitle, setPageTitle] = useState("Home");
  const [showNotification, setShowNotification] = useState(false);

  const { isLogout } = useSelector((state) => state?.user || {});

  const handleProfile = (event) => {
    setProfilePopUp(event.currentTarget);
  };

  const handleNotification = (event) => {
    setNotificationPopup(event.currentTarget);
  };

  const handleProfilePopupClose = () => {
    setProfilePopUp(null);
  };

  const handleNotificationPopupClose = () => {
    setNotificationPopup(false);
  };

  const getLinkForTitle = (title) => {
    const titleToPathMapping = {
      Profile: "/me",
      Campaigns: "/campaign",
      Insights: "/stats",
      Messages: "/chat",
      Home: "/home",
    };

    return titleToPathMapping[title] || "/";
  };

  const getCurrentDateFormatted = () => {
    const currentDate = new Date();

    const options = {
      weekday: "long",
      day: "numeric",
      month: "short",
    };

    const formattedDate = new Intl.DateTimeFormat("en-US", options).format(
      currentDate
    );
    return formattedDate;
  };

  // Popover for profile
  const openProfilePopup = Boolean(ProfilePopUp);
  const id = openProfilePopup ? "simple-popover" : undefined;

  const openNotificationPopup = Boolean(NotificationPopup);

  const iconStyle = {
    marginRight: "8px",
    minWidth: "auto",
  };

  // dialog for logout
  const handledialogOpen = () => {
    setOpendialog(true);
  };

  const handledialogClose = () => {
    setOpendialog(false);
  };

  // Logout user
  const handleLogout = () => {
    dispatch(logoutUser());
    if (isLogout) {
      navigate("/");
    }
  };

  useEffect(() => {
    const path = location.pathname.toLowerCase();
    if (path.includes("/me")) {
      setPageTitle("Profile");
    } else if (path.includes("/campaign")) {
      setPageTitle("Campaigns");
    } else if (path.includes("/stats")) {
      setPageTitle("Insights");
    } else if (path.includes("/youtube")) {
      setPageTitle("Insights");
    } else if (path.includes("/reach")) {
      setPageTitle("Insights");
    } else if (path.includes("/engagement")) {
      setPageTitle("Insights");
    } else if (path.includes("/chat")) {
      setPageTitle("Messages");
    } else {
      setPageTitle("Home");
    }
  }, [location.pathname]);

  //   const blue = {
  //     50: '#F0F7FF',
  //     100: '#C2E0FF',
  //     200: '#80BFFF',
  //     300: '#66B2FF',
  //     400: '#3399FF',
  //     500: '#007FFF',
  //     600: '#0072E5',
  //     700: '#0059B2',
  //     800: '#004C99',
  //     900: '#003A75',
  //   };

  //   const grey = {
  //     50: '#F3F6F9',
  //     100: '#E5EAF2',
  //     200: '#DAE2ED',
  //     300: '#C7D0DD',
  //     400: '#B0B8C4',
  //     500: '#9DA8B7',
  //     600: '#6B7A90',
  //     700: '#434D5B',
  //     800: '#303740',
  //     900: '#1C2025',
  //   };

  //   const Tab = styled(BaseTab)`
  //   font-family: 'IBM Plex Sans', sans-serif;
  //   color: #fff;
  //   cursor: pointer;
  //   font-size: 0.875rem;
  //   font-weight: 600;
  //   background-color: transparent;
  //   width: 100%;
  //   padding: 10px 12px;
  //   margin: 6px;
  //   border: none;
  //   border-radius: 7px;
  //   display: flex;
  //   justify-content: center;

  //   &:hover {
  //     background-color: ${blue[400]};
  //   }

  //   &:focus {
  //     color: #fff;
  //     outline: 3px solid ${blue[200]};
  //   }

  //   &.${tabClasses.selected} {
  //     background-color: #fff;
  //     color: ${blue[600]};
  //   }

  //   &.${buttonClasses.disabled} {
  //     opacity: 0.5;
  //     cursor: not-allowed;
  //   }
  // `;

  //   const TabPanel = styled(BaseTabPanel)(
  //     ({ theme }) => `
  //   width: 100%;
  //   font-family: 'IBM Plex Sans', sans-serif;
  //   font-size: 0.875rem;
  //   padding: 20px 12px;
  //   background: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
  //   border: 1px solid ${theme.palette.mode === 'dark' ? grey[700] : grey[200]};
  //   border-radius: 12px;
  //   opacity: 0.6;
  //   `,
  //   );

  //   const TabsList = styled(BaseTabsList)(
  //     ({ theme }) => `
  //   min-width: 400px;
  //   background-color: ${blue[500]};
  //   border-radius: 12px;
  //   margin-bottom: 16px;
  //   display: flex;
  //   align-items: center;
  //   justify-content: center;
  //   align-content: space-between;
  //   box-shadow: 0px 4px 30px ${theme.palette.mode === 'dark' ? grey[900] : grey[200]};
  //   `,
  //   );
  const [activeTab, setActiveTab] = useState(0);

  const handleTabClick = (index) => {
    setActiveTab(index);
  };

  return (
    <div className="header">
      <div className="profile">
        <Link to={getLinkForTitle(pageTitle)} className="profile">
          {pageTitle}
        </Link>
      </div>
      <div className="profile-photo">
        <p className="time">{getCurrentDateFormatted()}</p>
        <div className="vl"></div>
        <div className="notification" onClick={handleNotification}>
          <img src={Notification} alt="notification" />
        </div>

        <Popover
          id={id}
          open={openNotificationPopup}
          anchorEl={NotificationPopup}
          onClose={handleNotificationPopupClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          PaperProps={{
            style: {
              margin: 0,
              padding: 0,
              padding: "0.5rem 0 0.5rem 0",
              borderRadius: "0.93rem",
            },
          }}
        >
          <div className="notification-container">
            <div className="notification-header">
              <span className="heading">Notifications</span>
              <img src={Close} alt="closeNotification" />
            </div>
            <div className="tabs">
              <button
                className={activeTab === 0 ? "tab active" : "tab"}
                onClick={() => handleTabClick(0)}
              >
                <span>All</span>
              </button>
              <button
                className={activeTab === 1 ? "tab active" : "tab"}
                onClick={() => handleTabClick(1)}
              >
                <span>Unread</span>
              </button>
              <button
                className={activeTab === 2 ? "tab active" : "tab"}
                onClick={() => handleTabClick(2)}
              >
                <span>Read</span>
              </button>
            </div>
            <div className="tab-content">
              {activeTab === 0 && (
                <div>
                  <img src={Close} alt="notification-icon" />
                  <span>Content for Requested Campaigns</span>
                </div>
              )}
              {activeTab === 1 && (
                <div>
                  <img src={Close} alt="notification-icon" />
                  <span>Content for Collaborations</span>
                </div>
              )}
              {activeTab === 2 && (
                <div>
                  <img src={Close} alt="notification-icon" />
                  <span>Content for Accepted Collaborations</span>
                </div>
              )}
            </div>
          </div>
        </Popover>

        <div className="profile-box" onClick={handleProfile}>
          <img src={Puma} alt="profile-pic" />
        </div>
        <Popover
          id={id}
          open={openProfilePopup}
          anchorEl={ProfilePopUp}
          onClose={handleProfilePopupClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          PaperProps={{
            style: {
              width: "12rem",
              margin: 0,
              padding: 0,
              borderRadius: "0.93rem",
            },
          }}
        >
          <List style={{ padding: "0.5rem 0.15rem 0.5rem 0.15rem" }}>
            <ListItem button component={Link} to="/me">
              <ListItemIcon style={iconStyle}>
                <img src={accountCircleIcon} alt="Profile Icon" />
              </ListItemIcon>
              <ListItemText primary="Profile" />
            </ListItem>
            <Divider />
            <ListItem button component={Link} to="/privacy">
              <ListItemIcon style={iconStyle}>
                <img src={shieldLockIcon} alt="Privacy Icon" />
              </ListItemIcon>
              <ListItemText primary="Privacy" />
            </ListItem>
            <Divider />
            <ListItem button component={Link} to="/settings">
              <ListItemIcon style={iconStyle}>
                <img src={settingsIcon} alt="Settings Icon" />
              </ListItemIcon>
              <ListItemText primary="Settings" />
            </ListItem>
            <Divider />
            {/* <ListItem button onClick={() => handleLogout()}> */}
            <ListItem button onClick={handledialogOpen}>
              <ListItemIcon style={iconStyle}>
                <img src={logoutIcon} alt="Logout Icon" />
              </ListItemIcon>
              <ListItemText primary="Logout" />
            </ListItem>
            <Dialog
              open={opendialog}
              onClose={handledialogClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              className=""
            >
              <DialogTitle id="alert-dialog-title">
                <img src={logoutIcon} alt="Logout Icon" />
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  <span>Leaving so soon?</span>
                  <br />
                  <span>Don't forget to save your progress before you go!</span>
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={handledialogClose}>cancel</Button>
                <Button onClick={handleLogout} autoFocus>
                  logout
                </Button>
              </DialogActions>
            </Dialog>
          </List>
        </Popover>
      </div>
    </div>
  );
}

export default Header;
