import React, { useEffect, useRef } from "react";
import Chart from "chart.js/auto";

const COLORS = ["#B9F3FC", "#F8BED4", "#BAEADC"];
const PieChart = ({ reachData }) => {
  const chartContainer = useRef(null);

  useEffect(() => {
    if (chartContainer && chartContainer.current) {
      const ctx = chartContainer.current.getContext("2d");

      const existingChart = Chart.getChart(ctx);
      if (existingChart) {
        existingChart.destroy();
      }

      new Chart(ctx, {
        type: "doughnut",
        data: {
          labels: reachData.map((entry) => entry.name),
          datasets: [
            {
              data: reachData.map((entry) => entry.value),
              backgroundColor: COLORS,
            },
          ],
        },
        options: {
          responsive: true,
          plugins: {
            legend: {
              display: false,
            },
            centerText: {
              text: "Genuine followers",

              color: "#6B4750", // Replace this with the desired text color
              fontStyle: "bold", // Replace this with the desired font style
              sidePadding: 20, // Adjust the padding from the doughnut
            },
          },
        },
      });
    }
  }, [reachData]);

  return (
    <div style={{ width: "13rem", height: "15rem" }}>
      <canvas ref={chartContainer} style={{ height: "100%", width: "100%" }} />
    </div>
  );
};

export default PieChart;
