import React from "react";
import Location from "../assets/Location.svg";
import Calender from "../assets/Calender.svg";
import Ongoing from "../assets/Ongoing.svg";  
import "./CampaignDetails.scss";
import User from "../assets/User.png";
import Filter from "../assets/Filter.svg";
import Sort from "../assets/Sort.svg";
import People from "../assets/People.svg";
import Accept from "../assets/accept.svg";
import Reject from "../assets/reject.svg";


function CampaignDetails() {
  function createData(name, calories, fat, carbs, protein) {
    return { name, calories, fat, carbs, protein };
  }

  const rows = [
    createData("Frozen yoghurt", 159, 6.0, 24, 4.0),
    createData("Ice cream sandwich", 237, 9.0, 37, 4.3),
    createData("Eclair", 262, 16.0, 24, 6.0),
  ];

  return (
    <div class="campaign-details">
      <div className="campaign-content">
      <div className="campaign-image">
      <div className="campaign-content">
            <div className="campaign-status">
              <span>Secret Santa</span>
              <img  src={Ongoing} alt="Ongoing Icon" />
              <span >Ongoing</span>
            </div>
            <div className="aim">Increasing Brand Awareness</div>
            <div className="required-content">
              <div className="reels">
                <span>1</span>
                <span>Reels</span>
              </div>
              <div className="posts">
              <span>1</span>
                <span>Reels</span>
              </div>
              <div className="languages">
              <span>Eng</span>
                <span>Eng</span>
              </div>
            </div>
            <div className="line">
            </div>
            <div className="location-calendar-info">
              <div className="calender-info">
                <img src={Calender} alt="Calender Icon" />
                <span>20th Dec to 27th Dec</span>
              </div>
              <div className="location-info">
              <img src={Location} alt="Calender Icon" />
                <span>Andheri west, Mumbai</span>
              </div>
            </div>
          </div>
      </div>
      <div class="requests">
      <div className="influencers-list">
          <p className="heading">Collaboration Requests for this Campaign</p>
          <table className="custom-table">
            <thead>
              <tr>
                <th>Influencers</th>
                <th>
                  Genre
                  <img src={Filter} alt="Filter Icon" className="filter-icon" />
                </th>
                <th>
                  Engagement %
                  <img src={Sort} alt="Sort Icon" className="sort-icon" />
                </th>
                <th>
                  Followers
                  <img src={Sort} alt="Sort Icon" className="sort-icon" />
                </th>
                <th className="audience-gender">
                  Audience Gender
                  <img src={Filter} alt="Filter Icon" className="filter-icon" />
                </th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {rows.map((row, index) => (
                <tr key={index}>
                  <td className="profile-info">
                    <div
                      className="profile-image"
                      style={{
                        backgroundImage: `url(${User})`,
                      }}
                    />
                    <span className="influencer-name">{row.name}</span>
                  </td>
                  <td className="genre">{row.calories}</td>
                  <td className="engagement">{row.fat}</td>
                  <td className="followers">
                    <div
                      className="followers-image"
                    >
                      <img src={People} alt="People Icon" />
                    <span>{row.carbs}</span>
                    </div>
                  </td>

                  <td>
                    <div className="audience-info">
                      <span className="male-percent gender-percentages">
                        Male: <span>10%</span>
                      </span>
                      <span className="female-percent gender-percentages">
                        Female:<span>60%</span>
                      </span>
                      <span className="other-percent gender-percentages">
                        Other: <span>30%</span>
                      </span>
                    </div>
                  </td>
                  <td >
                      <div className="actions">
                        <div className="accpet">
                          <span>Accept</span>
                          <img src={Accept} alt="Accept Icon" />
                        </div>
                        <div className="reject">
                          <span>Reject</span>
                          <img src={Reject} alt="Reject Icon" />  
                        </div>
                      </div>
                    </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      </div>
    </div>
  );
}

export default CampaignDetails;
