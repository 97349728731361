import React, { useEffect, useRef } from "react";
import { Chart } from "chart.js";

const HorizontalBarChart = ({ Bardata }) => {
  // Chart.js configuration
  const chartContainer = useRef(null);
  const chartInstance = useRef(null);

  useEffect(() => {
    if (chartContainer && chartContainer.current && Bardata) {
      const ctx = chartContainer.current.getContext("2d");

      // Create the Chart instance if it doesn't exist
      if (!chartInstance.current) {
        chartInstance.current = new Chart(ctx, {
          type: "bar",
          data: {
            labels: Bardata.map((entry) => entry.name),
            datasets: [
              {
                label: "Reach",
                data: Bardata.map((entry) => entry.value),
                backgroundColor: Bardata.map((entry) => entry.fill),
                borderColor: Bardata.map((entry) => entry.fill),
                borderWidth: 2,
                barThickness: 25,
              },
            ],
          },
          options: {
            indexAxis: "y",
            elements: {
              bar: {
                borderWidth: 2,
              },
            },
            responsive: true,
            plugins: {
              legend: {
                display: false,
              },
            },
            defaultFontFamily: "'Poppins', sans-serif",
            defaultFontColor: "brown",
          },
        });

        // Ensure chartContainer.current exists before adding event listener
        if (chartContainer.current) {
          // Example event listener (you can replace this with your own logic)
          chartContainer.current.addEventListener("click", () => {
            console.log("Chart container clicked!");
          });
        }
      } else {
        // Update the Chart data if it already exists
        chartInstance.current.data.labels = Bardata.map((entry) => entry.name);
        chartInstance.current.data.datasets[0].data = Bardata.map(
          (entry) => entry.value
        );
        chartInstance.current.data.datasets[0].backgroundColor = Bardata.map(
          (entry) => entry.fill
        );
        chartInstance.current.data.datasets[0].borderColor = Bardata.map(
          (entry) => entry.fill
        );

        chartInstance.current.update(); // Update the chart
      }
    }
  }, [Bardata]);

  return (
    <div style={{ width: "100%", height: "95%" }}>
      <canvas
        ref={chartContainer}
        width={1300}
        height={320}
        style={{ width: "100%", height: "90%" }}
      />
    </div>
  );
};

export default HorizontalBarChart;
