import React from "react";
import "./CollabRequest.scss";
import Sampleimg from "../assets/sampleimg.png";
import Mailbox from "../assets/mailbox.svg";
import LocationSVG from "../assets/LocationSVG.svg";
import YoutubeButton from "../assets/youtubeButton.svg";
import InstagramIcon from "../assets/instagramICON.svg";
import BackVector from "../assets/BackVector.svg";
import InstagramAccordion from "../InfluencerStatsPage/InstaAccordion";
import YoutubeAccordion from "../InfluencerStatsPage/YoutubeAccordion";
import DoneIcon from "@mui/icons-material/Done";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
const InfluencerStats = () => {
  return (
    <div className="collab-influencer-stats">
      <button>
        <img src={BackVector} alt="back" />
        back
      </button>
      <div className="stat-item">
        <div className="contact">
          <p>Contact</p>
          <div className="contact-details">
            <div className="sub-div">
              <img src={Mailbox} alt="mail" />
              <small> johnmorgan@gmail.com</small>
            </div>
            <div className="sub-div">
              <img src={LocationSVG} alt="location" />
              <small> Andheri west, Mumbai</small>
            </div>
          </div>
        </div>
        <div className="profile">
          <h3>Cardi B</h3>
          <div className="image-box">
            <img src={Sampleimg} alt="img" />
          </div>
          <p>Fashion</p>
        </div>
        <div className="contact">
          <p>Socials</p>
          <div className="contact-details">
            <div className="sub-div">
              <img src={InstagramIcon} alt="instagram" />
              <small>john_morgan</small>
            </div>
            <div className="sub-div">
              <img src={YoutubeButton} alt="youtube" className="youtube" />
              <small> www.youtube.com/@realtalkclipp</small>
            </div>
          </div>
        </div>
      </div>

      <div className="note-from-influencer">
        <h3>Note from Cardi B</h3>
        <p>
          hey puma massive fan here! Your product totally speaks to my interest
          and I've been racking it for a long time. I'd love to collaborate and
          see how it changes my lifestyle.
        </p>
      </div>

      <InstagramAccordion />

      <YoutubeAccordion />

      <div className="collab-buttons">
        <button className="accept">
          Accept collab Request <DoneIcon />
        </button>
        <button className="reject">
          Reject <CloseRoundedIcon />
        </button>
      </div>
    </div>
  );
};

export default InfluencerStats;
