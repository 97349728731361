import {
  CREATE_BRAND_PROFILE_REQUEST,
  CREATE_BRAND_PROFILE_SUCCESS,
  CREATE_BRAND_PROFILE_FAIL,
  UPDATE_PROFILE_PHOTO_FAIL,
  UPDATE_PROFILE_PHOTO_SUCCESS,
  UPDATE_PROFILE_PHOTO_REQUEST,
  GET_PROFILE_DETAILS_FOR_BRAND_REQUEST,
  GET_PROFILE_DETAILS_FOR_BRAND_SUCCESS,
  GET_PROFILE_DETAILS_FOR_BRAND_FAIL,
  UPDATE_BRAND_CONTACT_INFO_REQUEST,
  UPDATE_BRAND_CONTACT_INFO_SUCCESS,
  UPDATE_BRAND_PROFILE_INFO_FAIL,
  UPDATE_BRAND_PROFILE_INFO_REQUEST,
  UPDATE_BRAND_PROFILE_INFO_SUCCESS,
} from "../constants/profileConstants";
import axios from "axios";

export const createBrandProfile = (brandProfileData) => async (dispatch) => {
  dispatch({ type: CREATE_BRAND_PROFILE_REQUEST });
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}/api/v1/brand-profile`,
      brandProfileData,
      {
        headers: {
          'Content-Type': 'application/json',
        },
        withCredentials: true,
      }
    );
    dispatch({ type: CREATE_BRAND_PROFILE_SUCCESS, payload: response.data });
  } catch (error) {
    dispatch({ type: CREATE_BRAND_PROFILE_FAIL, payload: error.message });
  }
};

export const updateProfilePhoto = (profilePhotoAndCategoriesData) => async (dispatch) => {

  dispatch({ type: UPDATE_PROFILE_PHOTO_REQUEST });

  try {
    const formData = new FormData();
    formData.append('user_photo', profilePhotoAndCategoriesData);

    const response = await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}/api/v1/upload-profile-photo`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        withCredentials: true,
      }
    );

    dispatch({ type: UPDATE_PROFILE_PHOTO_SUCCESS, payload: response.data });
  } catch (error) {
    dispatch({ type: UPDATE_PROFILE_PHOTO_FAIL, payload: error.message });
  }
};


export const getProfileDetailsForBrand = () => async (dispatch) => {
  dispatch({ type: GET_PROFILE_DETAILS_FOR_BRAND_REQUEST });
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}/api/v1/get-brand-profile`,
      {
        withCredentials: true,
      }
    );
    dispatch({ type: GET_PROFILE_DETAILS_FOR_BRAND_SUCCESS, payload: response.data });
  } catch (error) {
    dispatch({ type: GET_PROFILE_DETAILS_FOR_BRAND_FAIL, payload: error.message });
  }
};

export const updateBrandContactInfo = (brandProfileData) => async (dispatch) => {
  dispatch({ type: UPDATE_BRAND_CONTACT_INFO_REQUEST });

  try {
    const response = await axios.put(
      `${process.env.REACT_APP_BACKEND_URL}/api/v1/update-bio-location-social`,
      brandProfileData,
      {
        withCredentials: true,
      }
    );
    dispatch({ type: UPDATE_BRAND_CONTACT_INFO_SUCCESS, payload: response.data });
  } catch (error) {
    dispatch({ type: UPDATE_BRAND_PROFILE_INFO_FAIL, payload: error.message });
  }
};

//update-profile-info
export const updateBrandProfileInfo = (brandProfileData) => async (dispatch) => {
  dispatch({ type: UPDATE_BRAND_PROFILE_INFO_REQUEST });
  try {
    const response = await axios.put(
      `${process.env.REACT_APP_BACKEND_URL}/api/v1/update-photo-categories`,
      brandProfileData, 
      { withCredentials: true }
    );
    dispatch({ type: UPDATE_BRAND_PROFILE_INFO_SUCCESS, payload: response.data });
  } catch (error) {
    dispatch({ type: UPDATE_BRAND_PROFILE_INFO_FAIL, payload: error.message });
  }
};

