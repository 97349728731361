// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.profile-section-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding-top: 32px;
  padding-bottom: 85px;
  background: var(--Bg, #F9F5F6);
}
.profile-section-container .custom-toast-body {
  width: 300px;
}`, "",{"version":3,"sources":["webpack://./src/components/ProfileSection/ProfileSection.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,uBAAA;EACA,sBAAA;EACA,mBAAA;EACA,iBAAA;EACA,oBAAA;EACA,8BAAA;AACJ;AACI;EACI,YAAA;AACR","sourcesContent":[".profile-section-container{\n    display: flex;\n    justify-content: center;\n    flex-direction: column;\n    align-items: center;\n    padding-top: 32px;\n    padding-bottom: 85px;\n    background: var(--Bg, #F9F5F6);\n\n    .custom-toast-body {\n        width: 300px; \n      }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
