import React from 'react'
import './Payment.scss'
import SearchIcon from "../assets/Search.svg";

const Payment = () => {

    const handleSearch = (event) => {
        console.log(event.target.value);
    };

    const columnWidths = [15, 20, 20, 15, 15, 10];

    const dummyData = [
        {
            invoiceNumber: '51468',
            brandName: 'Puma',
            campaignName: 'Secret Santa',
            billingDate: '2/19/23',
            amount: '5000',
            status: 'Paid',
        },
        {
            invoiceNumber: '54673',
            brandName: 'Nike',
            campaignName: 'BBD Sale',
            billingDate: '5/17/23',
            amount: '10,000',
            status: 'Paid',
        },
        {
            invoiceNumber: '13457',
            brandName: 'uncle Max Pan Cakes',
            campaignName: 'Amazon Sale',
            billingDate: '9/18/23',
            amount: '2000',
            status: 'Unpaid',
        },
        {
            invoiceNumber: '54407',
            brandName: 'Samsung',
            campaignName: 'Galaxy S24',
            billingDate: '2/11/23',
            amount: '15,000',
            status: 'Paid',
        },
        {
            invoiceNumber: '12434',
            brandName: 'Realrne',
            campaignName: 'NarZ0 Series',
            billingDate: '9/18/16',
            amount: '12,000',
            status: 'Unpaid',
        },
        {
            invoiceNumber: '2130',
            brandName: 'Morna Earth',
            campaignName: 'Onion Shampoo',
            billingDate: '1/28/17',
            amount: '8000',
            status: 'Paid',
        },
        {
            invoiceNumber: '04391',
            brandName: 'Wow Life Science co, Ltd',
            campaignName: 'Vitamin C Face Wash',
            billingDate: '5/27/15',
            amount: '1000',
            status: 'Paid',
        },
        {
            invoiceNumber: '04392',
            brandName: 'Guy Hawkins',
            campaignName: 'Guy Hawkins',
            billingDate: '8/2/19',
            amount: '17,000',
            status: 'Paid',
        },
        {
            invoiceNumber: '04398',
            brandName: 'Guy Hawkins',
            campaignName: 'Guy Hawkins',
            billingDate: '8/2/19',
            amount: '15,000',
            status: 'Paid',
        },
        {
            invoiceNumber: '04393',
            brandName: 'Guy Hawkins',
            campaignName: 'Guy Hawkins',
            billingDate: '8/2/19',
            amount: '7,000',
            status: 'Unpaid',
        },
        {
            invoiceNumber: '04397',
            brandName: 'Guy Hawkins',
            campaignName: 'Guy Hawkins',
            billingDate: '8/2/19',
            amount: '9,000',
            status: 'Paid',
        },
        {
            invoiceNumber: '04391',
            brandName: 'Guy Hawkins',
            campaignName: 'Guy Hawkins',
            billingDate: '8/2/19',
            amount: '19,000',
            status: 'Paid',
        },
    ];


    return (
        <div className='payment-container'>
            <div className='payment-header'>
                <div className='payment-header-left'>
                    <span className='payment-heading'>Payments</span>
                    <span className='payment-subheading'>Manage your billing and payment details</span>
                </div>
                <div className='payment-header-right'>
                    <div className="search">
                        <img src={SearchIcon} alt="Search Icon" className="search-icon" />
                        <input
                            className="search-bar"
                            type="text"
                            placeholder="Search"
                            onChange={handleSearch}
                        />
                    </div>
                    <div className='download'>
                        <img src={SearchIcon} alt="download Icon" className="download-icon" />
                        <span className='download-text'>Download PDF Report</span>
                    </div>
                </div>
            </div>
            <hr className='vertical-line' />
            <div className='payment-table'>
                <table>
                    <thead>
                        <tr className='payment-table-heading'>
                            <th style={{ width: `${columnWidths[0]}%` }}>INVOICE NUMBER</th>
                            <th style={{ width: `${columnWidths[1]}%` }}>Brand Name</th>
                            <th style={{ width: `${columnWidths[2]}%` }}>Campaign Name</th>
                            <th style={{ width: `${columnWidths[3]}%` }}>BILLING DATE</th>
                            <th style={{ width: `${columnWidths[4]}%` }}>AMOUNT</th>
                            <th style={{ width: `${columnWidths[5]}%` }}>STATUS</th>
                        </tr>
                    </thead>
                    <tbody>
                        {dummyData.map((data, index) => {
                            return (
                                <tr className='payment-table-content' key={index}>
                                    <td>{data.invoiceNumber}</td>
                                    <td>{data.brandName}</td>
                                    <td>{data.campaignName}</td>
                                    <td>{data.billingDate}</td>
                                    <td>{data.amount}</td>
                                    <td>
                                        <div className='payment-status'>
                                            <span className={data.status === 'Paid' ? 'status-paid' : 'status-unpaid'}>
                                                {data.status}
                                            </span>
                                        </div>
                                    </td>
                                </tr>
                            )
                        }
                        )}
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default Payment
