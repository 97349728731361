// Stepper.js

import React from "react";
import "./Stepper.scss";

function Stepper({
  currentStep,
  completedSteps,
  steps,
  handleNext,
  handlePrev,
}) {
  const isStepCompleted = (stepId) => completedSteps.includes(stepId);

  const handleBackButtonClick = () => {
    const activeStep = document.querySelector(
      `.stepper-step.active:not(.completed)`
    );

    if (activeStep) {
      activeStep.classList.remove("active");
    }
  };

  const handleStepClick = (event, step) => {
    // Prevent the default click behavior
    event.preventDefault();
    // Optionally, you can add additional logic here if needed
  };

  return (
    <div className="stepper-container">
      {steps.map((step) => (
        <div
          key={step.id}
          className={`stepper-step ${
            currentStep === step.id
              ? "active"
              : isStepCompleted(step.id)
              ? "completed"
              : ""
          }`}
          onClick={(event) => handleStepClick(event, step)}
        >
          {step.label}
          {step.id <= steps.length && <div className="stepper-line"></div>}
        </div>
      ))}

      <div className="stepper-buttons">
        {currentStep > 1 && (
          <button
            className="back-to-campaign-home-button"
            onClick={() => {
              handleBackButtonClick();
              handlePrev();
            }}
            style={{ display: "none" }}
          >
            Back
          </button>
        )}

        {currentStep < steps.length && (
          <button onClick={handleNext} style={{ display: "none" }}>
            Next
          </button>
        )}
      </div>
    </div>
  );
}

export default Stepper;
