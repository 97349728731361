export const GET_ALL_INFLUENCERS_REQUEST = "GET_ALL_INFLUENCERS_REQUEST";
export const GET_ALL_INFLUENCERS_SUCCESS = "GET_ALL_INFLUENCERS_SUCCESS";
export const GET_ALL_INFLUENCERS_FAIL = "GET_ALL_INFLUENCERS_FAIL";

export const TOGGLE_LIKE_REQUEST = 'TOGGLE_LIKE_REQUEST';
export const TOGGLE_LIKE_SUCCESS = 'TOGGLE_LIKE_SUCCESS';
export const TOGGLE_LIKE_FAILURE = 'TOGGLE_LIKE_FAILURE';

export const GET_SEARCH_INFLUENCERS_REQUEST = "GET_SEARCH_INFLUENCERS_REQUEST";
export const GET_SEARCH_INFLUENCERS_SUCCESS = "GET_SEARCH_INFLUENCERS_SUCCESS";
export const GET_SEARCH_INFLUENCERS_FAIL = "GET_SEARCH_INFLUENCERS_FAIL";

export const GET_ALL_LIKED_INFLUENCERS_REQUEST = "GET_ALL_LIKED_INFLUENCERS_REQUEST";
export const GET_ALL_LIKED_INFLUENCERS_SUCCESS = "GET_ALL_LIKED_INFLUENCERS_SUCCESS";
export const GET_ALL_LIKED_INFLUENCERS_FAIL = "GET_ALL_LIKED_INFLUENCERS_FAIL";

export const GET_INFLUENCER_APPLIED_CAMPAIGNS_REQUEST= "GET_INFLUENCER_APPLIED_CAMPAIGNS_REQUEST";
export const GET_INFLUENCER_APPLIED_CAMPAIGNS_SUCCESS = "GET_INFLUENCER_APPLIED_CAMPAIGNS_SUCCESS";
export const GET_INFLUENCER_APPLIED_CAMPAIGNS_FAIL = "GET_INFLUENCER_APPLIED_CAMPAIGNS_FAIL";

export const CLEAR_ERROR = "CLEAR_ERROR";