import axios from "axios";
import {
  GET_ALL_INFLUENCERS_REQUEST,
  GET_ALL_INFLUENCERS_SUCCESS,
  GET_ALL_INFLUENCERS_FAIL,
  GET_SEARCH_INFLUENCERS_REQUEST,
  GET_SEARCH_INFLUENCERS_SUCCESS,
  GET_SEARCH_INFLUENCERS_FAIL,
  TOGGLE_LIKE_REQUEST,
  TOGGLE_LIKE_SUCCESS,
  TOGGLE_LIKE_FAILURE,
  GET_ALL_LIKED_INFLUENCERS_REQUEST,
  GET_ALL_LIKED_INFLUENCERS_SUCCESS,
  GET_ALL_LIKED_INFLUENCERS_FAIL,
  GET_INFLUENCER_APPLIED_CAMPAIGNS_REQUEST,
  GET_INFLUENCER_APPLIED_CAMPAIGNS_SUCCESS,
  GET_INFLUENCER_APPLIED_CAMPAIGNS_FAIL,
} from "../constants/influencersConstants";

const getQueryString = (queryParams) => {
  if (!queryParams) {
    return ""; // Return an empty string if queryParams is undefined or null
  }

  const queryString = Object.entries(queryParams)
    .filter(([key, value]) => {
      if (Array.isArray(value)) {
        return value.length > 0; // Filter out keys with no selected options
      } else {
        return value !== undefined && value !== null; // Filter out keys with undefined or null values
      }
    })
    .map(([key, value]) => {
      const formattedKey = key.toLowerCase();
      if (Array.isArray(value)) {
        const formattedValue = Array.from(new Set(value)).join(",");
        return `${formattedKey}=${formattedValue}`;
      } else {
        return `${formattedKey}=${value}`;
      }
    })
    .join("&");

  return queryString ? `${queryString}` : "";
};

export const getAllInfluencers = (queryParams) => async (dispatch) => {
  dispatch({ type: GET_ALL_INFLUENCERS_REQUEST });
  try {
    const queryString = getQueryString(queryParams);
    const response = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}/api/v1/influencers?${queryString}`,
      {
        withCredentials: true,
      }
    );

    dispatch({
      type: GET_ALL_INFLUENCERS_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    console.error("Error fetching influencers:", error);
    dispatch({
      type: GET_ALL_INFLUENCERS_FAIL,
      payload: "Internal Server Error",
    });
  }
};

export const updateInfluencerLikes = (influencerId) => async (dispatch) => {
  try {
    dispatch({ type: TOGGLE_LIKE_REQUEST,payload: influencerId, });

    const { data } = await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}/api/v1/liked-influencer`,
      influencerId,
      {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      }
    );

    dispatch({
      type: TOGGLE_LIKE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    console.error("Error updating influencer likes:", error);
    dispatch({
      type: TOGGLE_LIKE_FAILURE,
      payload: "Internal Server Error",
    });
  }
};

export const getSearchedInfluencers = (searchTerm) => async (dispatch) => {
  dispatch({ type: GET_SEARCH_INFLUENCERS_REQUEST });
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}/api/v1/search-influencer?search=${searchTerm}`,
      {
        withCredentials: true,
      }
    );
    dispatch({
      type: GET_SEARCH_INFLUENCERS_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    console.error("Error fetching influencers:", error);
    dispatch({
      type: GET_SEARCH_INFLUENCERS_FAIL,
      payload: "Internal Server Error",
    });
  }
};

export const getFavoriteInfluencers = () => async (dispatch) => {
  dispatch({ type: GET_ALL_LIKED_INFLUENCERS_REQUEST });
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}/api/v1/all-liked-influencer`,
      {
        withCredentials: true,
      }
    );

    dispatch({
      type: GET_ALL_LIKED_INFLUENCERS_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    console.error("Error fetching influencers:", error);
    dispatch({
      type: GET_ALL_LIKED_INFLUENCERS_FAIL,
      payload: "Internal Server Error",
    });
  }
};

export const getInfluencerAppliedCampaigns = () => async (dispatch) => {
  dispatch({ type: GET_INFLUENCER_APPLIED_CAMPAIGNS_REQUEST });
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}/api/v1/requests`,
      {
        withCredentials: true,
      }
    );

    dispatch({
      type: GET_INFLUENCER_APPLIED_CAMPAIGNS_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    console.error("Error fetching influencers:", error);
    dispatch({
      type: GET_INFLUENCER_APPLIED_CAMPAIGNS_FAIL,
      payload: "Internal Server Error",
    });
  }
};
