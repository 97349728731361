import {
  CREATE_CAMPAIGN_REQUEST,
  CREATE_CAMPAIGN_SUCCESS,
  CREATE_CAMPAIGN_FAIL,
  SET_TARGET_AUDIENCE_REQUEST,
  SET_TARGET_AUDIENCE_SUCCESS,
  SET_TARGET_AUDIENCE_FAIL,
  SET_CAMPAIGN_DATES_REQUEST,
  SET_CAMPAIGN_DATES_SUCCESS,
  SET_CAMPAIGN_DATES_FAIL,
  CAMPAIGN_OVERVIEW_REQUEST,
  CAMPAIGN_OVERVIEW_FAIL,
  CAMPAIGN_OVERVIEW_SUCCESS,
  SET_PUBLISH_STATUS_REQUEST,
  SET_PUBLISH_STATUS_SUCCESS,
  SET_PUBLISH_STATUS_FAIL,
  GET_ALL_BRAND_CAMPAIGNS_REQUEST,
  GET_ALL_BRAND_CAMPAIGNS_SUCCESS,
  GET_ALL_BRAND_CAMPAIGNS_FAIL,
  DELETE_CAMPAIGN_REQUEST,
  DELETE_CAMPAIGN_SUCCESS,
  DELETE_CAMPAIGN_FAIL,
  GET_CAMPAIGNS_FOR_INFLUENCER_REQUEST,
  GET_CAMPAIGNS_FOR_INFLUENCER_SUCCESS,
  GET_CAMPAIGNS_FOR_INFLUENCER_FAIL,
  MAKE_CAMPAIGN_REQUEST_BY_INFLUENCER,
  MAKE_CAMPAIGN_SUCCESS_BY_INFLUENCER,
  MAKE_CAMPAIGN_FAIL_BY_INFLUENCER,
  ACCEPT_CAMPAIGN_REQUEST_BY_BRAND,
  ACCEPT_CAMPAIGN_SUCCESS_BY_BRAND,
  ACCEPT_CAMPAIGN_FAIL_BY_BRAND,
  GET_CAMPAIGN_REQUESTS_FOR_BRAND,
  GET_CAMPAIGN_REQUESTS_SUCCESS_FOR_BRAND,
  GET_CAMPAIGN_REQUESTS_FAIL_FOR_BRAND,
  SAVE_CAMPAIGN_REQUEST,
  SAVE_CAMPAIGN_SUCCESS,
  SAVE_CAMPAIGN_FAIL,
  MAKE_CAMPAIGN_RESET_BY_INFLUENCER,
  SAVE_CAMPAIGN_RESET,
  UPDATE_CAMPAIGN_REQUEST,
  UPDATE_CAMPAIGN_SUCCESS,
  UPDATE_CAMPAIGN_FAIL,
  UPDATE_TARGET_AUDIENCE_REQUEST,
  UPDATE_TARGET_AUDIENCE_SUCCESS,
  UPDATE_TARGET_AUDIENCE_FAIL,
  UPDATE_CAMPAIGN_DATES_REQUEST,
  UPDATE_CAMPAIGN_DATES_SUCCESS,
  UPDATE_CAMPAIGN_DATES_FAIL
} from "../constants/campaignConstants";
import { CLEAR_ERROR } from "../constants/userConstants";

export const campaignReducer = (state = {}, action) => {
  switch (action.type) {
    case CREATE_CAMPAIGN_REQUEST:
      return {
        ...state,
        loading: true,
        isCampaignCreated: false,
      };

    case SET_TARGET_AUDIENCE_REQUEST:
      return {
        ...state,
        loading: true,
        isTargetAudienceSetted: false,
      };

    case SET_CAMPAIGN_DATES_REQUEST:
      return {
        ...state,
        loading: true,
        isCampaignDatesSetted: false,
      };

    case CAMPAIGN_OVERVIEW_REQUEST:
      return {
        ...state,
        loading: true,
        isCampaignOverviewSuccess: false,
      };

    case SET_PUBLISH_STATUS_REQUEST:
      return {
        ...state,
        loading: true,
        isCampaignPublished: false,
      };

    case UPDATE_CAMPAIGN_REQUEST:
      return {
        ...state,
        loading: true,
        isCampaignUpdated: false,
      }

    case UPDATE_TARGET_AUDIENCE_REQUEST:
      return {
        ...state,
        loading: true,
        isTargetAudienceUpdated: false
      }

    case UPDATE_CAMPAIGN_DATES_REQUEST:
      return {
        ...state,
        loading: true,
        isCampaignDatesUpdated: false,
      }

    case CREATE_CAMPAIGN_SUCCESS:
      return {
        ...state,
        loading: false,
        isCampaignCreated: true,
        campaign: action.payload,
      };

    case SET_TARGET_AUDIENCE_SUCCESS:
      return {
        ...state,
        loading: false,
        isTargetAudienceSetted: true,
        campaign: action.payload,
      };

    case SET_CAMPAIGN_DATES_SUCCESS:
      return {
        ...state,
        loading: false,
        isCampaignDatesSetted: true,
        campaign: action.payload,
      };

    case CAMPAIGN_OVERVIEW_SUCCESS:
      return {
        ...state,
        loading: false,
        isCampaignOverviewSuccess: true,
        campaign: action.payload,
      };

    case SET_PUBLISH_STATUS_SUCCESS:
      return {
        ...state,
        loading: false,
        isCampaignPublished: true,
        campaign: action.payload,
      };

    case UPDATE_CAMPAIGN_SUCCESS:
      return {
        ...state,
        loading: false,
        isCampaignUpdated: true,
        campaign: action.payload
      }

    case UPDATE_TARGET_AUDIENCE_SUCCESS:
      return{
        ...state,
        loading: false,
        isTargetAudienceUpdated: true,
        campaign: action.payload
      }
    
    case UPDATE_CAMPAIGN_DATES_SUCCESS:
      return{
        ...state,
        loading: false,
        isCampaignDatesUpdated: true,
        campaign: action.payload
      }

    case CREATE_CAMPAIGN_FAIL:
    case SET_TARGET_AUDIENCE_FAIL:
    case SET_CAMPAIGN_DATES_FAIL:
    case CAMPAIGN_OVERVIEW_FAIL:
    case SET_PUBLISH_STATUS_FAIL:
      return {
        ...state,
        loading: false,
        isCampaignCreated: false,
        error: action.payload,
      };

    case UPDATE_CAMPAIGN_FAIL:
      return {
        ...state,
        loading: false,
        isCampaignUpdated: false,
        error: action.payload,
      }

      case UPDATE_TARGET_AUDIENCE_FAIL:
        return{
          ...state,
          loading: false,
          isTargetAudienceUpdated: false,
          campaign: action.payload
        }
      
      case UPDATE_CAMPAIGN_DATES_FAIL:
        return{
          ...state,
          loading: false,
          isCampaignDatesUpdated: false,
          campaign: action.payload
        }

    case CLEAR_ERROR:
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
};

export const getAllBrandCampaignsReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_ALL_BRAND_CAMPAIGNS_REQUEST:
    case GET_CAMPAIGNS_FOR_INFLUENCER_REQUEST:
      return {
        ...state,
        loading: true,
        campaigns: [],
      };
    case GET_ALL_BRAND_CAMPAIGNS_SUCCESS:
    case GET_CAMPAIGNS_FOR_INFLUENCER_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        campaigns: action.payload.campaigns,
      };
    case GET_ALL_BRAND_CAMPAIGNS_FAIL:
    case GET_CAMPAIGNS_FOR_INFLUENCER_FAIL:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.payload,
      };
    case DELETE_CAMPAIGN_REQUEST:
      return {
        ...state,
        loading: true,
        isDeleted: false,
      };

    case DELETE_CAMPAIGN_SUCCESS:
      return {
        ...state,
        loading: false,
        isDeleted: true,
      };

    case DELETE_CAMPAIGN_FAIL:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const makeCampaignRequestByInfluencerReducer = (state = {}, action) => {
  switch (action.type) {
    case MAKE_CAMPAIGN_REQUEST_BY_INFLUENCER:
      return {
        ...state,
        loading: true,
        requestMade: false
      };
    case MAKE_CAMPAIGN_SUCCESS_BY_INFLUENCER:
      return {
        ...state,
        loading: false,
        requestMade: true
      };
    case MAKE_CAMPAIGN_FAIL_BY_INFLUENCER:
      return {
        ...state,
        loading: false,
        error: action.payload,
        requestMade: false
      };
    case MAKE_CAMPAIGN_RESET_BY_INFLUENCER:
      return {
        ...state,
        requestMade: false
      };
    default:
      return state;
  }
};

export const acceptCampaignRequestByBrandReducer = (state = {}, action) => {
  switch (action.type) {
    case ACCEPT_CAMPAIGN_REQUEST_BY_BRAND:
      return {
        ...state,
        loading: true,
        isRequestSuccess: false
      };
    case ACCEPT_CAMPAIGN_SUCCESS_BY_BRAND:
      return {
        ...state,
        loading: false, 
        isRequestSuccess: true
       };
    case ACCEPT_CAMPAIGN_FAIL_BY_BRAND:
      return {
        ...state,
        loading: false, 
        error: action.payload,
        isRequestSuccess: false
      };
    default:
      return state;
  }
};

export const getCampaignRequestsForBrandReducer = (
  state = { requests: [] },
  action
) => {
  switch (action.type) {
    case GET_CAMPAIGN_REQUESTS_FOR_BRAND:
      return {
        ...state,
        loading: true,
        requests: []
      };
    case GET_CAMPAIGN_REQUESTS_SUCCESS_FOR_BRAND:
      return {
        ...state,
        loading: false, 
        requests: action.payload.requests
      };
    case GET_CAMPAIGN_REQUESTS_FAIL_FOR_BRAND:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    default:
      return state;
  }
};

export const saveCampaignReducer = (state = {}, action) => {
  switch (action.type) {
    case SAVE_CAMPAIGN_REQUEST:
      return {
        ...state,
        loading: true,
        isCampaignSaved: false,
      };
    case SAVE_CAMPAIGN_SUCCESS:
      return {
        ...state,
        loading: false,
        isCampaignSaved: true,
      };
    case SAVE_CAMPAIGN_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
        isCampaignSaved: false,
      };
    case SAVE_CAMPAIGN_RESET:
      return {
        ...state,
        isCampaignSaved: false,
      };
    default:
      return state;
  }
};
