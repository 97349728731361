import React, { useState } from "react";
import "./SideBar.scss";
import InSocialOrbit from "../assets/InSocialOrbit.svg";
import Home from "../assets/Home.svg";
import Stats from "../assets/Stats.svg";
import Campaigns from "../assets/Campaigns.svg";
import Chat from "../assets/Chat.svg";
import Payment from "../assets/Payment.svg";
import Payment_Hover from "../assets/Payment_Hover.svg";
import Home_Hover from "../assets/Home_Hover.svg";
import Stats_Hover from "../assets/Stats_Hover.svg";
import Campaigns_Hover from "../assets/Campaigns_Hover.svg";
import Chat_Hover from "../assets/Chat_Hover.svg";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

function SideBar() {
  const [icons, setIcons] = useState({
    Home: Home,
    Campaigns: Campaigns,
    Stats: Stats,
    Chat: Chat,
    Payment: Payment,
  });

  const navigate = useNavigate();
  const { user } = useSelector((state) => state.user);


  const handleHover = (iconName) => {
    switch (iconName) {
      case "Home":
        setIcons({ ...icons, Home: Home_Hover });
        break;
      case "Campaigns":
        setIcons({ ...icons, Campaigns: Campaigns_Hover });
        break;
      case "Stats":
        setIcons({ ...icons, Stats: Stats_Hover });
        break;
      case "Chat":
        setIcons({ ...icons, Chat: Chat_Hover });
        break;
      case "Payment":
        setIcons({ ...icons, Payment: Payment_Hover });
        break;
      default:
        break;
    }
  };

  const handleMouseOut = (iconName) => {
    switch (iconName) {
      case "Home":
        setIcons({ ...icons, Home: Home });
        break;
      case "Campaigns":
        setIcons({ ...icons, Campaigns: Campaigns });
        break;
      case "Stats":
        setIcons({ ...icons, Stats: Stats });
        break;
      case "Chat":
        setIcons({ ...icons, Chat: Chat });
        break;
      case "Payment":
        setIcons({ ...icons, Payment: Payment });
        break;
      default:
        break;
    }
  };

  const handleItemClick = (itemName) => {
    switch (itemName) {
      case "InfluencerDashboard":
        navigate("/home");
        break;
      case "Stats":
        navigate("/stats");
        break;
      case "Chat":
        navigate("/chat");
        break;
      case "Campaign":
        // {user?.role === "brand" ? navigate("/campaign") : navigate("/influencercampaign")}
        navigate("/campaign");
        break;
      case "Payment":
        navigate("/payment"); 
        break;
      default:
        break;
    }
  };

  return (
    <div className="sidebar">
      <img className="InSocialOrbit" src={InSocialOrbit} alt="InSocialOrbit" />

      <div className="home">
        <img
          src={icons.Home}
          alt="Home"
          onMouseOver={() => handleHover("Home")}
          onMouseOut={() => handleMouseOut("Home")}
          onClick={() => handleItemClick("InfluencerDashboard")}
        />
        <span className="sidebar-text">Home</span>
      </div>

      <div className="campaign">
        <img
          src={icons.Campaigns}
          alt="Campaigns"
          onMouseOver={() => handleHover("Campaigns")}
          onMouseOut={() => handleMouseOut("Campaigns")}
          onClick={() => handleItemClick("Campaign")}
        />
        <span className="sidebar-text">Campaigns</span>
      </div>

      {user?.role === "brand" && ( 
        <div className="stats" onClick={() => handleItemClick("Stats")}>
          <img
            src={icons.Stats}
            alt="Stats"
            onMouseOver={() => handleHover("Stats")}
            onMouseOut={() => handleMouseOut("Stats")}
          />
          <span className="sidebar-text">Insights</span>
        </div>
      )}

      <div className="chat" onClick={() => handleItemClick("Chat")}>
        <img
          src={icons.Chat}
          alt="Chat"
          onMouseOver={() => handleHover("Chat")}
          onMouseOut={() => handleMouseOut("Chat")}
        />
        <span className="sidebar-text">Messages</span>
      </div>

      {user?.role === "influencer" && ( 
        <>
          <div className="payment" onClick={() => handleItemClick("Payment")}>
            <img
              src={icons.Payment}
              alt="Payment"
              onMouseOver={() => handleHover("Payment")}
              onMouseOut={() => handleMouseOut("Payment")}
            />
            <span className="sidebar-text">Payment</span>
          </div>
        </>
      )}
    </div>
  );
}

export default SideBar;
