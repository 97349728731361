import React, { useEffect, useRef } from 'react';
import Chart from 'chart.js/auto';

const COLORS = ['#DF567C', '#68E484', '#68C8E4'];

function CustomLineChart({ data }) {
  const chartContainer = useRef(null);

  useEffect(() => {
    if (chartContainer && chartContainer.current) {
      const ctx = chartContainer.current.getContext('2d');

      new Chart(ctx, {
        type: 'line',
        data: {
          labels: data.map(entry => entry.name),
          datasets: [
            {
              label: 'Live Stream',
              data: data.map(entry => entry.live_stream),
              borderColor: COLORS[0],
              backgroundColor: 'transparent',
              pointRadius: 4,
              pointHoverRadius: 6,
              borderWidth: 2,
            },
            {
              label: 'Shorts',
              data: data.map(entry => entry.shorts),
              borderColor: COLORS[1],
              backgroundColor: 'transparent',
              pointRadius: 4,
              pointHoverRadius: 6,
              borderWidth: 2,
            },
            {
              label: 'Video',
              data: data.map(entry => entry.video),
              borderColor: COLORS[2],
              backgroundColor: 'transparent',
              pointRadius: 4,
              pointHoverRadius: 6,
              borderWidth: 2,
            },
          ],
        },
        options: {
          responsive: true,
          plugins: {
            legend: {
              display: false,
            },
          },
          scales: {
            x: {
              display: true,
              title: {
                display: true,
              },
              grid: {
                color: 'rgba(0, 0, 0, 0.1)', 
                borderDash: [5, 5], 
              },
            },
            y: {
              display: true,
              title: {
                display: true,
              },
              grid: {
                color: 'rgba(0, 0, 0, 0.1)', 
                borderDash: [5, 5], 
              },
            },
          },
        },
        
      });
    }
  }, [data]);

  return (
    <div style={{ width: '100%', height: '95%' }}>
      <canvas ref={chartContainer} style={{ width: '100%', height: '90%' }}/>
    </div>  
  );
}

export default CustomLineChart;
