import React, {useRef,useEffect} from 'react'
import Chart from 'chart.js/auto';

const BarChart = ({data}) => {
  const chartContainer = useRef(null);

  useEffect(() => {
    if(chartContainer && chartContainer.current){
      const ctx = chartContainer.current.getContext('2d');

      new Chart(ctx, {
        type: 'bar',
        data: {
          labels: data.map(entry => entry.type),
          datasets: [
            {
              label: 'Video',
              data: data.map(entry => entry.value),
              backgroundColor: data.map(entry => entry.fill),
              barThickness: 50,
            },
          ],
        },
        options: {
          responsive: true,
          indexAxis: 'x',
          plugins: {
            legend: {
              display: false,
            },
            tooltip: {
              mode: 'index',
              intersect: false,
            },
            datalabels: {
              anchor: 'end',
              align: 'top',
              formatter: Math.round,
              font: {
                weight: 'bold'
              }
            },
          },
          scales: {
            x: {
              grid: {
                display: true,
                drawBorder: false,
              },
            },
            y: {
              grid: {
                display: true,
                drawBorder: false,
              },
              grace: '10%',
            },
          },
        },
      });
    }
  }, [data]);

  return (
    <div style={{ width: '100%', height: '100%' }}>
      <canvas ref={chartContainer} style={{ width: '100%', height: '100%' }}/>
    </div>
  )
}

export default BarChart
