// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sidebar {
  width: 7vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px 0.5rem;
  overflow: hidden;
}
.sidebar .InSocialOrbit {
  margin-bottom: 3.5rem;
  margin-top: 20px;
  width: 60px;
  height: 60px;
}
.sidebar div {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 40px;
}
.sidebar div img {
  display: block;
  width: 60px;
  height: 60px;
  transition: filter 0.3s ease;
}
.sidebar div img:hover {
  cursor: pointer;
  filter: brightness(1.2) hue-rotate(10deg);
}
.sidebar div .sidebar-text {
  transition: font-size 0.3s ease;
  overflow: hidden;
  margin-top: 12px;
}
.sidebar div img:hover + .sidebar-text {
  font-size: 16px;
}
.sidebar div span {
  color: var(--Light, #e4688a);
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}`, "",{"version":3,"sources":["webpack://./src/components/SideBar/SideBar.scss"],"names":[],"mappings":"AAAA;EACE,UAAA;EACA,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,mBAAA;EACA,gBAAA;AACF;AACE;EACE,qBAAA;EACA,gBAAA;EACA,WAAA;EACA,YAAA;AACJ;AAEE;EACE,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,mBAAA;AAAJ;AAEI;EACE,cAAA;EACA,WAAA;EACA,YAAA;EACA,4BAAA;AAAN;AAGI;EACE,eAAA;EACA,yCAAA;AADN;AAII;EACI,+BAAA;EACA,gBAAA;EACA,gBAAA;AAFR;AAKI;EACI,eAAA;AAHR;AAMI;EACE,4BAAA;EACA,oBAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,mBAAA;AAJN","sourcesContent":[".sidebar {\n  width: 7vw;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  padding: 0px 0.5rem;\n  overflow: hidden;\n\n  .InSocialOrbit {\n    margin-bottom: 3.5rem;\n    margin-top: 20px;\n    width: 60px;\n    height: 60px;\n  }\n\n  div {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    margin-bottom: 40px;\n\n    img {\n      display: block;\n      width: 60px;\n      height: 60px;\n      transition: filter 0.3s ease;\n    }\n\n    img:hover {\n      cursor: pointer;\n      filter: brightness(1.2) hue-rotate(10deg);\n    }\n\n    .sidebar-text {\n        transition: font-size 0.3s ease; \n        overflow: hidden;\n        margin-top: 12px;\n    }\n\n    img:hover + .sidebar-text {\n        font-size: 16px; \n      }\n\n    span {\n      color: var(--Light, #e4688a);\n      font-family: Poppins;\n      font-size: 14px;\n      font-style: normal;\n      font-weight: 600;\n      line-height: normal;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
