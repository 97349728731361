import React from "react";
import "./Stats.scss";
import ArrowSVG from "../assets/ArrowSVG.svg";
import { PieChart, Pie, Cell } from "recharts";
import { createElement } from "react";
import Reels from "../assets/reel.svg";
import Story from "../assets/instastory.svg";
import Posts from "../assets/postframe.svg";
import YoutubeShorts from "../assets/youtubeshorts.svg";
import YoutubeLive from "../assets/youtubelive.svg";
import YoutubeVideos from "../assets/youtubevideos.svg";
import Trophy from "../assets/Trophy.svg";
import Man from "../assets/man.png";
import Woman from "../assets/woman.png";
import { useNavigate } from "react-router-dom";
import HorizontalChart from "./HorizontalChart";

const data = [
  { name: "Ongoing", value: 20 },
  { name: "Scheduled", value: 10 },
  { name: "Completed", value: 20 },
];

const COLORS = ["#D0FCD7", "#FDD5DC", "#B9F3FC"];

const Bardata = [
  { name: "Likes", value: 6000, fill: "#FDD5DC" },
  { name: "Comments", value: 8000, fill: "#B3EBE8" },
  { name: "Saves", value: 4000, fill: "#D0FCD7" },
  { name: "Shares", value: 3000, fill: "#B9F3FC" },
];

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
  index,
}) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);
  return createElement(
    "text",
    {
      x: x,
      y: y,
      fill: "#6B4750",
      textAnchor: x > cx ? "start" : "end",
      dominantBaseline: "central",
    },
    `${(percent * 100).toFixed(0)}%`
  );
};

const Stats = () => {
  const navigate = useNavigate();

  const handleEngagementClick = () => {
    navigate("/engagement");
  };

  const handleReachClick = () => {
    navigate("/reach");
  };

  const handleInstagramClick = () => {
    const platform = "Instagram";
    navigate("/youtube", { state: { platform } });
  };

  const handleYouTubeClick = () => {
    const platform = "YouTube";
    navigate("/youtube", { state: { platform } });
  };

  return (
    <div className="wrapping-box">
      <div className="wrapping-box-content">
        <div className="first-container">
          <div className="left-container">
            <div className="options-content">
              <span className="platformtitle">Total Campaigns Posted</span>
              <span className="view-all">
                View All <img src={ArrowSVG} alt="arrow" />
              </span>
            </div>
            <div className="pie-chart">
              <PieChart width={332} height={332}>
                <Pie
                  data={data}
                  cx="50%"
                  cy="50%"
                  labelLine={false}
                  label={renderCustomizedLabel}
                  outerRadius={100}
                  fill="#6B4750"
                  dataKey="value"
                >
                  {data.map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={COLORS[index % COLORS.length]}
                    />
                  ))}
                </Pie>
              </PieChart>
            </div>
            <div className="bottom-content">
              <span className="number-of-campaigns">
                Total 50 campaigns posted
              </span>
            </div>
          </div>
          <div className="right-container">
            <div className="social-corner">
              <div className="ig-box" onClick={handleInstagramClick}>
                <div className="text-content">
                  <span>Instagram</span>
                </div>
                <div className="socials-stats">
                  <div className="reels">
                    <img src={Reels} alt="reels" />
                    <p>12</p>
                    <small>Reels</small>
                  </div>
                  <div className="reels">
                    <img src={Story} alt="story" />
                    <p>12</p>
                    <small> Story</small>
                  </div>
                  <div className="reels">
                    <img src={Posts} alt="posts" />
                    <p>12</p>
                    <small>Posts</small>
                  </div>
                </div>
              </div>
              <div className="ig-box" onClick={handleYouTubeClick}>
                <div className="text-content">
                  <span>Youtube</span>
                </div>
                <div className="socials-stats">
                  <div className="reels">
                    <img src={YoutubeShorts} alt="reels" />
                    <p>12</p>
                    <small>Shorts</small>
                  </div>
                  <div className="reels">
                    <img src={YoutubeVideos} alt="story" />
                    <p>12</p>
                    <small> Videos</small>
                  </div>
                  <div className="reels">
                    <img src={YoutubeLive} alt="posts" />
                    <p>12</p>
                    <small>Live</small>
                  </div>
                </div>
              </div>
            </div>
            <div className="social-corner">
              <div className="ig-box">
                <div className="text-content">
                  <span>Total Engagement Score</span>
                </div>
                <div className="number-content" onClick={handleEngagementClick}>
                  <p>6%</p>
                  <small onClick={handleEngagementClick}>
                    Audience Engaged
                  </small>
                </div>
              </div>
              <div className="ig-box">
                <div className="text-content">
                  <span>Total Reach</span>
                </div>
                <div className="number-content" onClick={handleReachClick}>
                  <p>10,234</p>
                  <small onClick={handleReachClick}>Audience Reached</small>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="second-container">
          <div className="options-content">
            <span className="platformtitle">Audience Interactions</span>
          </div>
          <div className="horizontal-bar-chart">
            <HorizontalChart Bardata={Bardata} />
          </div>
        </div>
        <div className="third-container">
          <div className="campaign-performance-box">
            <div className="platformtitle">
              <span>Top Performing campaigns</span>
            </div>
            <div className="campaign-names">
              <div className="campaign-date">
                <ul>
                  <li>Secret Santa</li>
                </ul>
                <p>12th Dec</p>
              </div>
              <div className="campaign-date">
                <ul>
                  <li>Great Indian Sale</li>
                </ul>
                <p>3rd Oct</p>
              </div>
              <div className="campaign-date">
                <ul>
                  <li>Big Billion Days</li>
                </ul>
                <p>26th Aug</p>
              </div>
            </div>
          </div>
          <div className="influencer-performance-box">
            <div className="platformtitle">
              <span>Top Performing Influencers</span>
            </div>
            <div className="influencer-wrapper-container">
              <div className="influencer-1">
                <div className="nameofinfluencer">
                  <img src={Man} alt="nameofinfluencer" />
                  <p>Gaurav Taneja</p>
                </div>
                <div className="audience-stats">
                  <p>6K</p>
                  <span>Audience Reached</span>
                  <p>10%</p>
                  <span>Audience Engaged</span>
                </div>
              </div>
              <div className="trophy-image">
                <img src={Trophy} alt="trophy" />
              </div>
              <div className="influencer-1">
                <div className="nameofinfluencer">
                  <img src={Woman} alt="nameofinfluencer" />
                  <p>Cardi B</p>
                </div>
                <div className="audience-stats">
                  <p>4K</p>
                  <span>Audience Reached</span>
                  <p>8%</p>
                  <span>Audience Engaged</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Stats;
