import React, { useEffect, useRef } from 'react'
import Chart from 'chart.js/auto';

const COLORS = ['#B3EBE8', '#FDD5DC', '#D0FCD7', '#B9F3FC'];
const PieChart = ({ data }) => {
  const chartContainer = useRef(null);

  useEffect(() => {
    if (chartContainer && chartContainer.current) {
      const ctx = chartContainer.current.getContext('2d');

      new Chart(ctx, {
        type: 'doughnut',
        data: {
          labels: data.map(entry => entry.name),
          datasets: [
            {
              data: data.map(entry => entry.value),
              backgroundColor: COLORS
            }
          ]
        },
        options: {
          responsive: true,
          plugins: {
            legend: {
              display: false
            }
          }
        }
      });
    }
  }, [data]);


  return (
    <div style={{ width: '332px', height: '332px' }}> 
      <canvas ref={chartContainer} style={{height: "100%" , width: "100%"}} />
    </div>
  )
}

export default PieChart;
