import React, { useEffect, useRef } from 'react';
import Chart from 'chart.js/auto';

const COLORS = '#C3E9F8';

function CustomBarChart({ data }) {
  const chartContainer = useRef(null);

  const maxValue = data.reduce((max, data) => (data.value > max ? data.value : max), data[0].value);
  useEffect(() => {
    if (chartContainer && chartContainer.current) {
      const ctx = chartContainer.current.getContext('2d');

      new Chart(ctx, {
        type: 'bar',
        data: {
          labels: data.map(entry => entry.type),
          datasets: [
            {
              label: 'Videos',
              data: data.map(entry => entry.value),
              backgroundColor: data.map(entry => entry.fill),
              barThickness: 50,
            },
          ],
        },
        options: {
          responsive: true,
          indexAxis: 'x',
          plugins: {
            legend: {
              display: false,
            },
            tooltip: {
              mode: 'index',
              intersect: false,
            },
          },
          scales: {
            x: {
              grid: {
                display: true,
                drawBorder: false,
              },
            },
            y: {
              grid: {
                display: true,
                drawBorder: false,
              },
              max: maxValue + 500,
            },
          },
        },
      });
    }
  }, [data]);

  return (
    <div style={{ width: '100%', height: '100%' }}>
      <canvas ref={chartContainer} style={{ width: '100%', height: '100%' }} />
    </div>
  );
}

export default CustomBarChart;
