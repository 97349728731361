import React, { useEffect, useState, useRef } from "react"; // Import useRef
import DefaultUserProfile from "../assets/DefaultUserProfile.jpeg";
import SendIcon from "../assets/SendIcon.png";
import "./Messages.scss";
import { useSelector } from "react-redux";
import axios from "axios";
import NoConversation from "../assets/NoConversation.png";
import AttachmentIcon from "@mui/icons-material/Attachment";
import { IconButton } from "@mui/material";

function Messages({ conversation, socket }) {
  const [message, setMessage] = useState("");
  const [messages, setMessages] = useState([]);
  const messagesEndRef = useRef(null);

  const { user } = useSelector((state) => state.user || {});

  useEffect(() => {
    socket?.emit("addUser", user?.user_id);
    socket?.on("getUsers", (users) => {
      console.log("activeUsers :>> ", users);
    });

    socket?.on("getMessage", (data) => {
      setMessages((prevMessages) => [
        ...prevMessages,
        { user: data.user, message: data.message },
      ]);
    });
  }, [socket, user]);

  useEffect(() => {
    if (conversation) {
      const senderId = user?.user_id;
      const receiverId = conversation.user.receiverId;
      axios
        .get(
          `http://localhost:5000/chat/message/${conversation.conversationId}?senderId=${senderId}&receiverId=${receiverId}`
        )
        .then((response) => {
          setMessages(response.data);
          scrollToBottom();
        })
        .catch((error) => {
          console.error("Error fetching messages:", error);
        });
    }
  }, [conversation, user]);

  const sendMessage = () => {
    // Clear the selected image
    setMessage("");

    if (!message && !message.trim()) return;

    if (message instanceof File) {
      const formData = new FormData();
      formData.append("image", message);
      formData.append("senderId", user?.user_id);
      formData.append("receiverId", conversation.user.receiverId);
      formData.append("conversationId", conversation.conversationId);

      axios
        .post("http://localhost:5000/chat/message", formData)
        .then((response) => {
          socket?.emit("sendMessage", {
            senderId: user?.user_id,
            receiverId: conversation.user.receiverId,
            message: response.data.image_url,
            conversationId: conversation.conversationId,
          });

          scrollToBottom();
        })
        .catch((error) => {
          console.error("Error sending image:", error);
        });
    } else {
      socket?.emit("sendMessage", {
        senderId: user?.user_id,
        receiverId: conversation.user.receiverId,
        message: message,
        conversationId: conversation.conversationId,
      });

      axios
        .post("http://localhost:5000/chat/message", {
          conversationId: conversation.conversationId,
          senderId: user.user_id,
          message: message,
          receiverId: conversation.user.receiverId,
        })
        .then((response) => {
          scrollToBottom();
        })
        .catch((error) => {
          console.error("Error sending message:", error);
        });
    }
  };

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <>
      {conversation ? (
        <div className="messages">
          <div className="user-profile">
            <img
              className="user-profile-photo"
              src={conversation.user.user_profile || DefaultUserProfile}
              alt="User Profile"
            />
            <div className="user-info">
              <span className="username">{conversation.user.fullName}</span>
              <span className="email">{conversation.user.email}</span>
            </div>
          </div>
          <div className="message-container">
            <ul>
              {conversation &&
                messages.map((message, index) => (
                  <li
                    key={index}
                    className={
                      message.user.id === user?.user_id ? "sender" : "receiver"
                    }
                  >
                    {message.message && message.message.startsWith("http") ? (
                      <img
                        src={message.message}
                        alt="Message"
                        className="message-image"
                        style={{ maxWidth: "200px", maxHeight: "200px" }}
                      />
                    ) : (
                      message.message
                    )}
                  </li>
                ))}
              <div ref={messagesEndRef} />
            </ul>
          </div>
          <div className="send-message">
            <input
              type="text"
              placeholder="Start a new message"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
            />
            {/* <label htmlFor="file-input" className="attachment-icon-label">
              <IconButton component="span">
                <AttachmentIcon />
              </IconButton>
            </label>
            <input
              id="file-input"
              type="file"
              accept="image/*"
              style={{ display: "none" }}
              onChange={(e) => {
                const file = e.target.files[0];
                if (file) {
                  setMessage(file);
                }
              }}
            /> */}
            <div className="send-button" onClick={sendMessage}>
              <img className="send" src={SendIcon} alt="send" />
            </div>
          </div>
        </div>
      ) : (
        <div className="no-conversation">
          <img src={NoConversation} alt="No Conversation" />
        </div>
      )}
    </>
  );
}

export default Messages;
