import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./SignupInfluencer.scss";
import UserIcon from "../assets/User.svg";
import GoogleIcon from "../assets/google 1.svg";
import Flag from "../assets/Flag Of India.svg";
import { MuiOtpInput } from "mui-one-time-password-input";

const InfluencerSignupHome = ({ onClick }) => (
  <div className="login-page">
    <div className="signup-page-logo">
      <img src={UserIcon} alt="logo" id="user-logo" />
      <span className="brand-text">Influencer</span>
    </div>

    <div className="influencer-header-text">
      <h1>Hi, social </h1>
      <h1>butterfly!</h1>
    </div>

    <button className="google-button" onClick={() => onClick("3")}>
      <img src={GoogleIcon} alt="Google Logo" className="google-logo" />
      Continue with Google
    </button>

    <div className="separator">
      <span></span>
      <p className="separator-text">OR</p>
      <span></span>
    </div>
    <div className="email-input-box">
      <input placeholder="Your E-mail Address" className="signup-email-input" />
      <button onClick={() => onClick("2")} className="submit-signup-button">
        Sign Up for Free
      </button>
    </div>

    <div className="bottom-text">
      <p>
        Already Have an Account? <a href="/login">Log In Here</a>
      </p>
      <p>
        Not an Influencer?
        <Link to="/brandsignup">Sign up as Brand</Link>
      </p>
    </div>
  </div>
);

const InfluencerSignupEmail = ({ onClick }) => (
  <div className="login-page">
    <div className="signup-page-logo">
      <img src={UserIcon} alt="Logo" id="user-logo" />
      <span className="brand-text">Influencer</span>
    </div>

    <div className="influencer-header-text">
      <h1>Hi, social </h1>
      <h1>butterfly!</h1>
    </div>
    <div className="email-signup-box">
      <label>Your E-mail Address</label>
      <input className="email-input" />
      <label>Enter OTP</label>
      <input className="email-input" maxLength={6} />
    </div>

    <button onClick={() => onClick("3")} className="submit-signup-button">
      Submit
    </button>
    <div className="bottom-text">
      <p>
        Already Have an Account? <a href="/login">Log In Here</a>
      </p>
      <p>
        Not an Influencer?
        <Link to="/brandsignup">Sign up as Brand</Link>
      </p>
    </div>
  </div>
);

const InfluencerPhoneVerification = ({ onClick }) => (
  <div className="login-page">
    <div className="signup-page-logo">
      <img src={UserIcon} alt="Logo" id="user-logo" />
      <span className="brand-text">Influencer</span>
    </div>

    <div className="influencer-header-text">
      <h1>Hi, social </h1>
      <h1>butterfly!</h1>
    </div>
    <div className="phone-detail-box">
      <img src={Flag} alt="flag-logo" id="flag-logo" />
      <input type="text" id="country-code" value="+91" readOnly />
      <input type="text" placeholder="Mobile No." id="phone-number-signup" />
    </div>

    <div className="signup-check-box">
      <input className="signup-checkbox" type="checkbox"></input>
      <label>Remember Me</label>
    </div>

    <button id="influencerpage-otp-button" onClick={() => onClick("4")}>
      Generate OTP
    </button>
    <div className="bottom-text">
      <p>
        Already Have an Account? <a href="/login">Log In Here</a>
      </p>
      <p>
        Not an Influencer?
        <Link to="/brandsignup">Sign up as Brand</Link>
      </p>
    </div>
  </div>
);

const OTPVerification = ({ onClick }) => {
  const [otp, setOtp] = useState("");

  const handleChange = (value) => {
    setOtp(value);
  };

  return (
    <div className="login-page">
      <div className="signup-page-logo">
        <img src={UserIcon} alt="Logo" id="user-logo" />
        <span className="brand-text">Influencer</span>
      </div>

      <div className="influencer-header-text">
        <h1>Hi, social </h1>
        <h1>butterfly!</h1>
      </div>
      <div>
        <MuiOtpInput
          value={otp}
          onChange={handleChange}
          length={6}
          separator={<span>-</span>}
          isInputNum
        />
      </div>
      <div className="user-pref-box">
        <div className="signup-check-box">
          <input className="signup-checkbox" type="checkbox"></input>
          <label>Remember Me</label>
        </div>
        <p onClick={() => onClick("3")} className="change-phone-number">
          Change Phone No.
        </p>
      </div>
      <button id="signup-generate-otp-button">Validate and Sign Up</button>

      <div className="bottom-text">
        <p>
          Already Have an Account? <a href="/login">Log In Here</a>
        </p>
        <p>
          Not an Influencer?
          <Link to="/brandsignup">Sign up as Brand</Link>
        </p>
      </div>
    </div>
  );
};

const LoginPage = () => {
  const [currentComponent, setCurrentComponent] = useState("1");

  const handleButtonClick = (component) => {
    setCurrentComponent(component);
  };

  return (
    <div className="home-div">
      {currentComponent === "1" && (
        <InfluencerSignupHome onClick={handleButtonClick} />
      )}
      {currentComponent === "2" && (
        <InfluencerSignupEmail onClick={handleButtonClick} />
      )}
      {currentComponent === "3" && (
        <InfluencerPhoneVerification onClick={handleButtonClick} />
      )}

      {currentComponent === "4" && (
        <OTPVerification onClick={handleButtonClick} />
      )}
    </div>
  );
};

export default LoginPage;
