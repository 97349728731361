import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  TextField,
} from "@mui/material";
import ConfirmationPopup from "./ConfirmationPopup";
import { useDispatch } from "react-redux";
import { makeCampaignRequestByInfluencer } from "../../actions/campaignAction";

function CollaboratePopup({ open, onClose, onSubmit,campaignId,brand_id }) {
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [message, setMessage] = useState("");
  const dispatch=useDispatch();

  const handleInputChange = (event) => {
    setMessage(event.target.value);
  };

  const handleCollabSubmit = () => {
    if (message.trim() !== "") {
      setConfirmationOpen(true);
      dispatch(makeCampaignRequestByInfluencer({campaign_id:campaignId,brand_id:brand_id,message:message}))
    }
  };

  const handleRequestWithoutNote = () => {
    dispatch(makeCampaignRequestByInfluencer({campaign_id:campaignId,brand_id:brand_id}))
    setConfirmationOpen(true);
  };

  const handleClose = () => {
    onClose();
  };

  const handleConfirmationClose = () => {
    setConfirmationOpen(false);
    onClose();
  };

  return (
    <>
      <Dialog
        open={open && !confirmationOpen}
        onClose={handleClose}
        maxWidth="md"
        fullWidth
        style={{ fontFamily: "Poppins", borderRadius: "10px" }}
      >
        <DialogTitle style={{ fontFamily: "Poppins" }}>
          You can customize this Collaboration Request (Optional)
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            style={{
              fontFamily: "Poppins",
              color: "grey",
              marginBottom: "1rem",
            }}
          >
            Spice up your pitch! Drop a quick line to show why you're the
            perfect match.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            onChange={handleInputChange}
            id="collaborateTextArea"
            label="Your Message"
            type="text"
            fullWidth
            fontFamily="Poppins"
            outline="none"
            multiline
            rows={5}
            maxRows={10}
            InputProps={{
              style: { fontFamily: "Poppins", color: "black" },
            }}
          />
        </DialogContent>
        <DialogActions
          style={{
            display: "flex",
            justifyContent: "space-between",
            margin: "1rem",
          }}
        >
          <p
            color="primary"
            style={{
              color: "#e43a67",
              cursor: "pointer",
              fontFamily: "Poppins",
              textDecoration: "underline",
              boxShadow: "none",
            }}
            onClick={handleRequestWithoutNote}
          >
            Send request without note
          </p>
          <Button
            onClick={handleCollabSubmit}
            color="primary"
            style={{
              backgroundColor: "#e43a67",
              color: "white",
              width: "fit-content",
              padding: "1rem",
              fontFamily: "Poppins",
              textTransform: "none",
              boxShadow: "none",
            }}
          >
            Send Collab Request
          </Button>
        </DialogActions>
      </Dialog>
      <ConfirmationPopup
        open={confirmationOpen}
        onClose={() => handleConfirmationClose()}
        message={message}
        withNote={message.trim() !== ""}
      />
    </>
  );
}

export default CollaboratePopup;
