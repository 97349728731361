import React, { useEffect, useState } from "react";
import EmailIcon from "../assets/Email.svg";
import FacebookIcon from "../assets/Facebook.svg";
import InstagramIcon from "../assets/Instagram.svg";
import PhoneIcon from "../assets/Phone.svg";
import YouTubeIcon from "../assets/YouTube.svg";
import Edit from "../assets/Edit.svg";
import AddIcon from "@mui/icons-material/Add";
import { useDispatch } from "react-redux";

import "./ContactDetails.scss";
import { updateBrandContactInfo } from "../../actions/profileAction";

function ContactDetails({ brandProfile }) {
  const [isInfoPopupVisible, setInfoPopupVisible] = useState(false);
  const [socialMediaLinks, setSocialMediaLinks] = useState([""]);
  const [inputLocation, setInputLocation] = useState("");
  const [inputStreet, setInputStreet] = useState("");
  const [inputPinCode, setInputPinCode] = useState("");
  const [inputBio, setInputBio] = useState("");
  const [selectedState, setSelectedState] = useState("");
  const [inputWebsite, setInputWebsite] = useState("");
  const { user } = brandProfile || {};
  const dispatch = useDispatch();

  const area = user?.area ;
  const state = user?.state ;
  const pincode = user?.pincode ;
  const youtube_link = user?.youtube_link ;
  const facebook_link = user?.facebook_link ;
  const instagram_link = user?.instagram_link;

  useEffect(() => {
    if (user) {
      setInputBio(user.bio || null);
      setInputLocation(user.flat_apartment || null);
      setInputPinCode(user.pincode || null);
      setSelectedState(user.state || null);
      setInputStreet(user.area || null)
      setInputWebsite(user.brand_website || null);
      setSocialMediaLinks([
        user.instagram_link || "",
        user.youtube_link || "",
        user.facebook_link || "",
      ]);
    }
  }, [user]);

  const handleUpdate = () => {
    const updatedData = {
      bio: inputBio,
      area: inputLocation,
      pincode: inputPinCode,
      state: selectedState,
      flat_apartment:inputLocation,
      social_links: socialMediaLinks,
    };

    dispatch(updateBrandContactInfo(updatedData));
    toggleBottomPopup(); 
  }

  const toggleBottomPopup = () => {
    setInfoPopupVisible(!isInfoPopupVisible);
  };

  const addSocialMediaLink = () => {
    if (socialMediaLinks.length < 3) {
      setSocialMediaLinks([...socialMediaLinks, ""]);
    }
  };

  const handleInputChange = (index, value) => {
    const updatedLinks = [...socialMediaLinks];
    updatedLinks[index] = value;
    setSocialMediaLinks(updatedLinks);
  };

  const handleLocationChange = (event) => {
    setInputLocation(event.target.value);
  };

  const handleStreetChange = (event) => {
    setInputStreet(event.target.value);
  };

  const handlePinCodeChange = (event) => {
    setInputPinCode(event.target.value);
  };

  const handleBioChange = (event) => {
    setInputBio(event.target.value);
  };

  const handleStateChange = (event) => {
    setSelectedState(event.target.value);
  };

  const handleWebsiteChange = (event) => {
    setInputWebsite(event.target.value);
  };

  const handleClearAll = () => {
    setSelectedState("");

    setInputBio("");
    setInputLocation("");
    setInputStreet("");
    setInputPinCode("");
  };

  const indianStates = [
    "Andhra Pradesh",
    "Arunachal Pradesh",
    "Assam",
    "Bihar",
    "Chhattisgarh",
    "Goa",
    "Gujarat",
    "Haryana",
    "Himachal Pradesh",
    "Jharkhand",
    "Karnataka",
    "Kerala",
    "Madhya Pradesh",
    "Maharashtra",
    "Manipur",
    "Meghalaya",
    "Mizoram",
    "Nagaland",
    "Odisha",
    "Punjab",
    "Rajasthan",
    "Sikkim",
    "Tamil Nadu",
    "Telangana",
    "Tripura",
    "Uttar Pradesh",
    "Uttarakhand",
    "West Bengal",
    "Andaman and Nicobar Islands",
    "Chandigarh",
    "Dadra and Nagar Haveli and Daman and Diu",
    "Delhi",
    "Lakshadweep",
    "Puducherry",
  ];

  return (
    <div className="contact-details-container">
      <div className="edit-section">
        <img src={Edit} alt="edit-icon" onClick={toggleBottomPopup} />
        <p className="edit-text">Edit</p>
        {isInfoPopupVisible && (
          <div className="popup-wrapper">
            <div className="edit-contact-details">
              <h2>Edit Profile</h2>
              <div className="location-label">
                <label>Location</label>
              </div>
              <div className="input-row">
                <div className="input-container">
                  <label
                    htmlFor="Location"
                    className="location-field-input-label"
                  >
                    Flat, Building, Apartment
                    <span className="span-profile-input-info">(Optional)</span>
                  </label>
                  <input
                    className="profile-input-field"
                    type="text"
                    id="location"
                    placeholder="Name of your Flat, Building, Apartment......"
                    value={inputLocation}
                    onChange={handleLocationChange}
                  />
                </div>
                <div className="input-container">
                  <label
                    htmlFor="street"
                    className="location-field-input-label"
                  >
                    Area, Street, Sector
                  </label>
                  <input
                    className="profile-input-field"
                    type="text"
                    id="street"
                    placeholder="Name of your Area, Street, Sector..."
                    value={inputStreet}
                    onChange={handleStreetChange}
                  />
                </div>
              </div>

              <div className="input-row">
                <div className="input-container">
                  <label htmlFor="state" className="location-field-input-label">
                    State
                  </label>
                  <select
                    className="profile-input-field"
                    type="text"
                    id="state"
                    placeholder="Select Your State"
                    value={selectedState}
                    onChange={handleStateChange}
                  >
                    <option value="">Select Your State</option>
                    {indianStates.map((state) => (
                      <option key={state} value={state}>
                        {state}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="input-container">
                  <label
                    htmlFor="pincode"
                    className="location-field-input-label"
                  >
                    PIN Code
                  </label>
                  <input
                    className="profile-input-field"
                    type="text"
                    id="code"
                    placeholder="6 Digit PIN Code"
                    value={inputPinCode}
                    onChange={handlePinCodeChange}
                  />
                </div>
              </div>
              <div className="input-row">
                <div className="input-container">
                  <label htmlFor="bio" className="field-input-label">
                    Brand's Bio
                  </label>
                  <textarea
                    className="profile-input-field"
                    placeholder="About your brand"
                    rows="4"
                    cols="40"
                    value={inputBio}
                    onChange={handleBioChange}
                  ></textarea>
                </div>
              </div>
              <div className="input-group">
                <label htmlFor="website">
                  Your Brand's Website
                  <span className="span-profile-input-info">(Optional)</span>
                </label>
                <input type="text" id="website" value={inputWebsite} onChange={handleWebsiteChange} />
              </div>
              {socialMediaLinks.map((link, index) => (
                <div key={index} className="input-group">
                  <label htmlFor={`socialMedia${index}`}>
                    {index === 0 ? "Brand's Social Media Links" : ""}
                  </label>
                  <div className="input-with-icon">
                    <input
                      type="text"
                      id={`socialMedia${index}`}
                      value={link}
                      onChange={(e) => handleInputChange(index, e.target.value)}
                    />

                    {socialMediaLinks.length < 3 && (
                      <span className="add-icon" onClick={addSocialMediaLink}>
                        <AddIcon />
                      </span>
                    )}
                  </div>
                </div>
              ))}
              <div className="footer-buttons">
                <span id="clear-all-button" onClick={handleClearAll}>
                  Clear All
                </span>
                <div className="button-box-area">
                  <button
                    className="save-changes-button"
                    onClick={handleUpdate}
                  >
                    Save
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="brand-info">
        <div className="about-brand">
          <p className="section-title">About Brand</p>
          <p className="content">{user?.bio || "no bio avaiable"}</p>
        </div>
        <div className="contact-info">
          <p className="section-title">Contact</p>
          <div className="contact-details">
            <div className="contact-section">
              <img src={EmailIcon} alt="contact-icon" />
              <p className="email-text">{user?.email || "No email"}</p>
            </div>
            <div className="contact-section">
              <img src={PhoneIcon} alt="contact-icon" />
              <p className="phone-text">
                +91{" "}
                {user?.phone_no
                  ? user.phone_no.substring(2)
                  : "No phone number available"}
              </p>
            </div>
          </div>
        </div>
        <div className="location-info">
          <p className="section-title">Location</p>
          <p className="location">
            {area}, {state}, {pincode}
          </p>
        </div>
        <div className="social-info">
          <p className="section-title">Social Links</p>
          <div className="social-links">
            {instagram_link ? (
              <div className="social-section">
                <img src={InstagramIcon} alt="social-icon" />
                <p className="social-text">{instagram_link}</p>
              </div>
            ) : null}
            {youtube_link ? (
              <div className="social-section">
                <img src={YouTubeIcon} alt="social-icon" />
                <p className="social-text">{youtube_link}</p>
              </div>
            ) : null}

            {facebook_link ? (
              <div className="social-section">
                <img src={FacebookIcon} alt="social-icon" />
                <p className="social-text">{facebook_link}</p>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ContactDetails;
