import {
  GET_ALL_INFLUENCERS_REQUEST,
  GET_ALL_INFLUENCERS_SUCCESS,
  GET_ALL_INFLUENCERS_FAIL,
  TOGGLE_LIKE_REQUEST,
  TOGGLE_LIKE_SUCCESS,
  TOGGLE_LIKE_FAILURE,
  CLEAR_ERROR,
  GET_SEARCH_INFLUENCERS_REQUEST,
  GET_SEARCH_INFLUENCERS_SUCCESS,
  GET_SEARCH_INFLUENCERS_FAIL,
  GET_ALL_LIKED_INFLUENCERS_REQUEST,
  GET_ALL_LIKED_INFLUENCERS_SUCCESS,
  GET_ALL_LIKED_INFLUENCERS_FAIL,
} from "../constants/influencersConstants";

export const influencersReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_ALL_INFLUENCERS_REQUEST:
    case GET_SEARCH_INFLUENCERS_REQUEST:
    case GET_ALL_LIKED_INFLUENCERS_REQUEST:
      return {
        ...state,
        loading: true,
        success: false,
      };
    case GET_ALL_INFLUENCERS_SUCCESS:
    case GET_SEARCH_INFLUENCERS_SUCCESS:
    case GET_ALL_LIKED_INFLUENCERS_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        influencers: action.payload.influencers,
      };
    case GET_ALL_INFLUENCERS_FAIL:
    case GET_SEARCH_INFLUENCERS_FAIL:
    case GET_ALL_LIKED_INFLUENCERS_FAIL:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.payload,
      };
    case TOGGLE_LIKE_REQUEST:
      return {
        ...state,
        loading: true,
        success: false,
        clickedInfluencer: action.payload.influencer_id,
      };
    case TOGGLE_LIKE_SUCCESS:
      {
        let updatedInfluencers = state.influencers.map((influencer) => {
          if (influencer.influencer_id === state.clickedInfluencer) {
            return {
              ...influencer,
              isliked: action.payload.liked,
            };
          } else return influencer;
        });
        return {
          ...state,
          loading: false,
          success: true,
          influencers: updatedInfluencers,
        };
      }
    // return {
    //   ...state,
    //   loading: false,
    //   success: true,
    //   isliked: action.payload.liked,
    // };
    case TOGGLE_LIKE_FAILURE:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.payload,
      };
    case CLEAR_ERROR:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

export const updateInfluencerLikesReducer = (state = {}, action) => {
  switch (action.type) {
    case TOGGLE_LIKE_REQUEST:
      return {
        ...state,
        loading: true,
        success: false,
      };
    case TOGGLE_LIKE_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        isliked: action.payload.liked,
      };
    case TOGGLE_LIKE_FAILURE:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.payload,
      };
    case CLEAR_ERROR:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
}