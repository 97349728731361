import React, { useEffect, useState } from "react";
import "./BrandProfile.scss";
import Puma from "../assets/Puma.png";
import Edit from "../assets/Edit.svg";
import ProfileIcon from "../assets/defaultprofile.svg";
import EditIcon from "@mui/icons-material/Edit";
import { updateBrandProfileInfo, updateProfilePhoto } from "../../actions/profileAction";
import { useDispatch, useSelector } from "react-redux";

function BrandProfile({ brandProfile }) {
  const [isPopupVisible, setPopupVisible] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [inputBrandName, setInputBrandName] = useState("");
  const { user, categories } = brandProfile || {};
  const [selectedPhoto, setSelectedPhoto] = useState(user?.user_photo);
  const [updateTrigger, setUpdateTrigger] = useState(false);
  const {profilePhoto}=useSelector((state)=>state.profielPhoto)

  const dispatch = useDispatch();

  useEffect(() => {
    if (user && categories) {
      setInputBrandName(user.brand_name || "");
      setSelectedCategory(
        categories && categories.length > 0 ? categories[0] : ""
      );
      setSelectedPhoto(user.user_photo)
    }
  }, [user, categories,updateTrigger]);

  useEffect(() => {
    if (profilePhoto && profilePhoto.userPhoto) {
      setSelectedPhoto(profilePhoto.userPhoto);
    }
  }, [profilePhoto]);

  const handleBrandPhotoChange = (event) => {
    const selectedFile = event.target.files[0];
    dispatch(updateProfilePhoto(selectedFile));
  };

  const togglePopup = () => {
    setPopupVisible(!isPopupVisible);
  };

  const handleBrandNameChange = (event) => {
    setInputBrandName(event.target.value);
  };

  const handleCategoryChange = (event) => {
    setSelectedCategory(event.target.value);
  };

  const handleClearAll = () => {
    setSelectedCategory("");
    setInputBrandName("");
  };

  const handleUpdate = () => {
    const updatedData = {
      brand_name: inputBrandName,
      brand_categories: [selectedCategory],
    };

    dispatch(updateBrandProfileInfo(updatedData));

    setUpdateTrigger((prev) => !prev);
    
    togglePopup();
  };

  return (
    <div className="brand-profile">
      <div className="profile-info">
        <div className="brand-photo">
          <img className="brand-logo"src={user?.user_photo}alt="profile-pic" />
        </div>
        <div className="brand-details">
          <span className="brand-name">{user?.brand_name || "no brand name"}</span>
          <span className="brand-category">
            {categories && categories.length > 0
              ? categories[0]
              : "No category available"}
          </span>
        </div>
      </div>
      <div className="edit-section">
        <img src={Edit} alt="edit-icon" onClick={togglePopup} />
        <p className="edit-text">Edit</p>

        {isPopupVisible && (
          <div className="popup-container">
            <div className="edit-profile-content">
              <h2>Edit Profile</h2>

              <div className="image-section">
                <label htmlFor="profileImage" className="image-label">
                  <img
                    src={selectedPhoto}
                    alt="profile-logo"
                    className="profile-icon-img"
                    />
                  <div className="edit-icon">
                    <EditIcon />
                  </div>
                </label>
                <input type="file" id="profileImage" accept="image/*" onChange={(event) => handleBrandPhotoChange(event)} />
              </div>
              <div className="input-row">
                <div className="input-container">
                  <label htmlFor="brandName" className="field-input-label">
                    Your Brand’s name
                  </label>
                  <input
                    className="profile-input-field"
                    type="text"
                    id="brandname"
                    placeholder="Brand's Name"
                    value={inputBrandName}
                    onChange={handleBrandNameChange}
                  />
                </div>
                <div className="input-container">
                  <label htmlFor="category" className="field-input-label">
                    Select Your Brand Category
                  </label>

                  <select
                    className="profile-input-field"
                    type="text"
                    placeholder="Select a Category"
                    value={selectedCategory}
                    onChange={handleCategoryChange}
                  >
                    <option value="">Select a Category</option>
                    <option value="Apparel & Footwear">
                      Apparel & Footwear
                    </option>
                    <option value="Beauty & Wellness">Beauty & Wellness</option>
                    <option value="Food & Beverage">Food & Beverage</option>
                    <option value="Home & Living">Home & Living</option>
                    <option value="Electronics & Appliances">
                      Electronics & Appliances
                    </option>
                    <option value="Travel & Tourism">Travel & Tourism</option>
                    <option value="Education & Training">
                      Education & Training
                    </option>
                    <option value="Professional Services">
                      Professional Services
                    </option>
                    <option value="Healthcare & Wellness">
                      Healthcare & Wellness
                    </option>
                    <option value="Entertainment & Media">
                      Entertainment & Media
                    </option>
                    <option value="India-Specific Categories">
                      India-Specific Categories
                    </option>
                    <option value="Traditional Wear">Traditional Wear</option>
                    <option value="Handicrafts & Home Decor">
                      Handicrafts & Home Decor
                    </option>
                    <option value="Ayurvedic & Natural Products">
                      Ayurvedic & Natural Products
                    </option>
                    <option value="Street Food & Local Cuisine">
                      Street Food & Local Cuisine
                    </option>
                    <option value="Festival & Occasion Wear">
                      Festival & Occasion Wear
                    </option>
                    <option value="Bollywood & Entertainment">
                      Bollywood & Entertainment
                    </option>
                    <option value="Cricket & Sports">Cricket & Sports</option>
                    <option value="Spiritual & Religious Products">
                      Spiritual & Religious Products
                    </option>
                    <option value="Educational Resources">
                      Educational Resources
                    </option>
                    <option value="Rural & Agricultural Products">
                      Rural & Agricultural Products
                    </option>
                  </select>
                </div>
              </div>
              <div className="footer-buttons">
                <span id="clear-all-button" onClick={handleClearAll}>
                  Clear All
                </span>
                <div className="button-box-area">
                  <button
                    className="save-changes-button"
                    onClick={handleUpdate}
                  >
                    Save
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default BrandProfile;
