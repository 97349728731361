import React, { useState } from 'react'
import './Filter.scss'
import SearchIcon from "../assets/Search.svg";

const Filter = () => {
    const [currentFilter, setCurrentFilter] = useState('Engagement');
    const [selectedOptions, setSelectedOptions] = useState({});
    const [filterOptionsData, setFilterOptionsData] = useState({});


    const filteroptions = {
        Engagement: [
            { label: "2% - 4%", value: "2-4" },
            { label: "5% - 7%", value: "5-7" },
            { label: "8% - 10%", value: "8-10" },
            { label: "11% - 13%", value: "11-13" },
            { label: "14% - 16%", value: "14-16" },
            { label: "16% +", value: "16+" }
        ],
        Reach: [
            { label: "O - 100K", value: "0 - 100000" },
            { label: "100K - 200K", value: "100000-200000" },
            { label: "200K - 300K", value: "200000-300000" },
            { label: "300K - 400K", value: "300000-400000" },
            { label: "400K - 500K", value: "400000-500000" },
            { label: "500K - 700K", value: "500000-700000" },
            { label: "700K - 900K", value: "700000-900000" },
            { label: "900K +", value: "900000 +" }
        ],
        Followers: [
            { label: "10K - 100K", value: "10000-100000" },
            { label: "100K - 300K", value: "100000-300000" },
            { label: "300K - 500K", value: "300000-500000" },
            { label: "500K - 700K", value: "500000-700000" },
            { label: "700K - 900K", value: "700000-900000" },
            { label: "900K - 1M", value: "900000-1000000" },
            { label: "1M - 3M", value: "1000000-3000000" },
            { label: "3M +", value: "3000000 +" }
        ],
        Genre: [
            { label: "Art & Photography", value: "Art & Photography" },
            { label: "Business & Entrepreneurship", value: "Business & Entrepreneurship" },
            { label: "Entertainment", value: "Entertainment" },
            { label: "Fitness, Fashion & Beauty", value: "Fitness, Fashion & Beauty" },
            { label: "Lifestyle & Travel", value: "Lifestyle & Travel" },
            { label: "Tech & Gaming", value: "Tech & Gaming" },
            { label: "Others", value: "Others" }
        ],
        Age: [
            { label: "13 - 17", value: "13-17" },
            { label: "18 - 24", value: "18-24" },
            { label: "25 - 34", value: "25-34" },
            { label: "35 - 44", value: "35-44" },
            { label: "45 - 54", value: "45-54" },
            { label: "55 - 64", value: "55-64" },
            { label: "65 +", value: "65+" },
        ],
        City: [
            { label: "Mumbai", value: "Mumbai" },
            { label: "Delhi", value: "Delhi" },
            { label: "Bangalore", value: "Bangalore" },
            { label: "Chennai", value: "Chennai" },
            { label: "Kolkata", value: "Kolkata" },
            { label: "Jaipur", value: "Jaipur" },
            { label: "Hyderabad", value: "Hyderabad" },
            { label: "Other Cities", value: "Other Cities" }
        ]
    }

    function handleClearAll() {
        setSelectedOptions({});
        setFilterOptionsData({});
    }

    function handleButtonClick(event) {
        const button = event.currentTarget;

        const buttons = document.querySelectorAll('.custom-button');
        buttons.forEach((btn) => {
            btn.classList.remove('active');
        });

        button.classList.add('active');

        const selectedFilter = event.currentTarget.textContent;
        setCurrentFilter(selectedFilter);
    }

    function handleCheckboxChange(option) {

        setSelectedOptions((prevSelectedOptions) => {
            const updatedOptions = {
                ...prevSelectedOptions,
                [currentFilter]: {
                    ...(prevSelectedOptions[currentFilter] || {}),
                    [option]: !prevSelectedOptions[currentFilter]?.[option],
                },
            };
            return updatedOptions; // Return the updated state
        });

        // Update filterOptionsData state for storing checked options
        setFilterOptionsData((prevFilterOptionsData) => {
            const currentOptions = prevFilterOptionsData[currentFilter] || [];

            const updatedOptions = {
                ...prevFilterOptionsData,
                [currentFilter]: selectedOptions[currentFilter]?.[option]
                    ? currentOptions.filter((item) => item !== option)
                    : [...currentOptions, option],
            };

            console.log(updatedOptions); // Log the updated state

            return updatedOptions; // Return the updated state
        });
    }

    return (
        <div className='filter-container'>
            <div className='left-container'>
                <div className='filter-title'>
                    <span>Filters</span>
                </div>
                <div className='filter-buttons'>
                    {Object.keys(filteroptions).map((button, index) => (
                        <button
                            key={index}
                            onClick={handleButtonClick}
                            className={`custom-button ${button === currentFilter ? 'active' : ''}`}
                        >
                            {button.label || button}
                        </button>
                    ))}
                </div>
            </div>
            <div className='right-container'>
                <div className="search">
                    <img
                        src={SearchIcon}
                        alt="Search Icon"
                        className="search-icon"
                    />
                    <input
                        className="search-bar"
                        type="text"
                        placeholder="Search"
                    />
                </div>
                <div className='options-container'>
                    {filteroptions[currentFilter].map((filter, index) => (
                        <div key={index} className='filter-option'>
                            <label>
                                <input
                                    type="checkbox"
                                    checked={selectedOptions[currentFilter]?.[filter.value] || false}
                                    onChange={() => handleCheckboxChange(filter.value)}
                                />
                                {filter.label}
                            </label>
                        </div>
                    ))}
                </div>
                <div className='results'>
                    <span>Showing results for 82 influencers</span>
                </div>
                <div className='bottom-container'>
                    <span onClick={handleClearAll} className='clear'>Clear all</span>
                    <button className='apply-button'>Apply Filters</button>
                </div>
            </div>
        </div>
    )
}

export default Filter
