import {
  CREATE_CAMPAIGN_REQUEST,
  CREATE_CAMPAIGN_SUCCESS,
  CREATE_CAMPAIGN_FAIL,
  SET_TARGET_AUDIENCE_REQUEST,
  SET_TARGET_AUDIENCE_SUCCESS,
  SET_TARGET_AUDIENCE_FAIL,
  SET_CAMPAIGN_DATES_REQUEST,
  SET_CAMPAIGN_DATES_SUCCESS,
  SET_CAMPAIGN_DATES_FAIL,
  CAMPAIGN_OVERVIEW_REQUEST,
  CAMPAIGN_OVERVIEW_SUCCESS,
  CAMPAIGN_OVERVIEW_FAIL,
  SET_PUBLISH_STATUS_REQUEST,
  SET_PUBLISH_STATUS_SUCCESS,
  SET_PUBLISH_STATUS_FAIL,
  SET_CAMPAIGN_ID_IN_REDUX,
  GET_ALL_BRAND_CAMPAIGNS_REQUEST,
  GET_ALL_BRAND_CAMPAIGNS_SUCCESS,
  GET_ALL_BRAND_CAMPAIGNS_FAIL,
  DELETE_CAMPAIGN_REQUEST,
  DELETE_CAMPAIGN_SUCCESS,
  DELETE_CAMPAIGN_FAIL,
  GET_CAMPAIGNS_FOR_INFLUENCER_REQUEST,
  GET_CAMPAIGNS_FOR_INFLUENCER_SUCCESS,
  GET_CAMPAIGNS_FOR_INFLUENCER_FAIL,
  MAKE_CAMPAIGN_REQUEST_BY_INFLUENCER,
  MAKE_CAMPAIGN_SUCCESS_BY_INFLUENCER,
  MAKE_CAMPAIGN_FAIL_BY_INFLUENCER,
  ACCEPT_CAMPAIGN_REQUEST_BY_BRAND,
  ACCEPT_CAMPAIGN_SUCCESS_BY_BRAND,
  ACCEPT_CAMPAIGN_FAIL_BY_BRAND,
  GET_CAMPAIGN_REQUESTS_FOR_BRAND,
  GET_CAMPAIGN_REQUESTS_SUCCESS_FOR_BRAND,
  GET_CAMPAIGN_REQUESTS_FAIL_FOR_BRAND,
  SAVE_CAMPAIGN_REQUEST,
  SAVE_CAMPAIGN_SUCCESS,
  SAVE_CAMPAIGN_FAIL,
  UPDATE_CAMPAIGN_REQUEST,
  UPDATE_CAMPAIGN_SUCCESS,
  UPDATE_CAMPAIGN_FAIL,
  UPDATE_TARGET_AUDIENCE_REQUEST,
  UPDATE_TARGET_AUDIENCE_SUCCESS,
  UPDATE_TARGET_AUDIENCE_FAIL,
  UPDATE_CAMPAIGN_DATES_REQUEST,
  UPDATE_CAMPAIGN_DATES_SUCCESS,
  UPDATE_CAMPAIGN_DATES_FAIL
} from "../constants/campaignConstants";
import axios from "axios";

export const createCampaign = (campaignData) => async (dispatch) => {
  try {
    dispatch({ type: CREATE_CAMPAIGN_REQUEST });

    const { data } = await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}/api/v1/create-campaign`,
      campaignData,
      {
        withCredentials: true,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    );

    dispatch({ type: CREATE_CAMPAIGN_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: CREATE_CAMPAIGN_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};


export const setTargetAudienceForCampaign = (audienceData) => async (dispatch) => {
  try {
    dispatch({ type: SET_TARGET_AUDIENCE_REQUEST });

    const { data } = await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}/api/v1/set-target-audience`,
      audienceData,
      { withCredentials: true }
    );

    dispatch({ type: SET_TARGET_AUDIENCE_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: SET_TARGET_AUDIENCE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const setCampaignDates = (datesData) => async (dispatch) => {
  try {
    dispatch({ type: SET_CAMPAIGN_DATES_REQUEST });
    const { data } = await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}/api/v1/set-campaign-dates`,
      datesData, 
      {
        headers: {
          'Content-Type': 'application/json', 
        },
        withCredentials: true,
      }
    );

    dispatch({ type: SET_CAMPAIGN_DATES_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: SET_CAMPAIGN_DATES_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};


export const getCampaignOverview = (campaign_id) => async (dispatch) => {
  try {
    dispatch({ type: CAMPAIGN_OVERVIEW_REQUEST });

    const { data } = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}/api/v1/campaign-overview?campaignId=${campaign_id}`,
      { withCredentials: true }
    );

    dispatch({ type: CAMPAIGN_OVERVIEW_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: CAMPAIGN_OVERVIEW_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const setPublishStatus = (publishData) => async (dispatch) => {
  try {
    dispatch({ type: SET_PUBLISH_STATUS_REQUEST });
    const { data } = await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}/api/v1/campaigns/status`,
      publishData, 
      {
        headers: {
          'Content-Type': 'application/json', 
        },
        withCredentials: true,
      }
    );

    dispatch({ type: SET_PUBLISH_STATUS_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: SET_PUBLISH_STATUS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const updateCampaign = (campaignData) => async (dispatch) => {
  try {
    dispatch({ type: UPDATE_CAMPAIGN_REQUEST });

    const { data } = await axios.put(
      `${process.env.REACT_APP_BACKEND_URL}/api/v1/update-campaign`,
      campaignData,
      { withCredentials: true }
    );

    dispatch({ type: UPDATE_CAMPAIGN_SUCCESS, payload: data });
  }
  catch (error) {
    dispatch({
      type: UPDATE_CAMPAIGN_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const updateTargetAudienceforCampaign = (audienceData) => async (dispatch) => {
  try {
    dispatch({ type: UPDATE_TARGET_AUDIENCE_REQUEST });
    const { data } = await axios.put(
      `${process.env.REACT_APP_BACKEND_URL}/api/v1/update-target-audience`,
      audienceData,
      { withCredentials: true }
    );

    dispatch({ type: UPDATE_TARGET_AUDIENCE_SUCCESS, payload: data });
  }
  catch (error) {
    dispatch({
      type: UPDATE_TARGET_AUDIENCE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const updateCampaignDates = (datesData) => async (dispatch) => {
  try {
    dispatch({ type: UPDATE_CAMPAIGN_DATES_REQUEST });
    const { data } = await axios.put(
      `${process.env.REACT_APP_BACKEND_URL}/api/v1/update-campaign-dates`,
      datesData,
      { withCredentials: true }
    );

    dispatch({ type: UPDATE_CAMPAIGN_DATES_SUCCESS, payload: data });
  }
  catch (error) {
    dispatch({
      type: UPDATE_CAMPAIGN_DATES_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const getAllCampaignsForBrand = () => async (dispatch) => {
  try {
    dispatch({ type: GET_ALL_BRAND_CAMPAIGNS_REQUEST });

    const { data } = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}/api/v1/campaigns`,
      { withCredentials: true }
    );

    dispatch({ type: GET_ALL_BRAND_CAMPAIGNS_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: GET_ALL_BRAND_CAMPAIGNS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const deleteCampaign = (campaignId) => async (dispatch) => {
  try {
    dispatch({ type: DELETE_CAMPAIGN_REQUEST });

    const { data } = await axios.delete(
      `${process.env.REACT_APP_BACKEND_URL}/api/v1/campaigns/${campaignId}`,
      { withCredentials: true }
    );

    dispatch({ type: DELETE_CAMPAIGN_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: DELETE_CAMPAIGN_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const getAllCampaignsForInfluencer = () => async (dispatch) => {
  try {
    dispatch({ type: GET_CAMPAIGNS_FOR_INFLUENCER_REQUEST });

    const { data } = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}/api/v1/all`,
      { withCredentials: true }
    );

    dispatch({ type: GET_CAMPAIGNS_FOR_INFLUENCER_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: GET_CAMPAIGNS_FOR_INFLUENCER_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const makeCampaignRequestByInfluencer = (requestData) => async (dispatch) => {
  try {
    dispatch({ type: MAKE_CAMPAIGN_REQUEST_BY_INFLUENCER });

    const response = await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}/api/v1/make-request`,
      requestData, 
      { withCredentials: true }
    );

    dispatch({ type: MAKE_CAMPAIGN_SUCCESS_BY_INFLUENCER, payload: response.data });
  } catch (error) {
    dispatch({
      type: MAKE_CAMPAIGN_FAIL_BY_INFLUENCER,
      payload: error.response ? error.response.data.message : error.message
    });
  }
};

export const acceptCampaignRequestByBrand = (requestData) => async (dispatch) => {
  try {
    dispatch({ type: ACCEPT_CAMPAIGN_REQUEST_BY_BRAND });

    const response = await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}/api/v1/accept-request`,
      requestData,
      { withCredentials: true }
    );

    dispatch({ type: ACCEPT_CAMPAIGN_SUCCESS_BY_BRAND, payload: response.data });
  } catch (error) {
    dispatch({
      type: ACCEPT_CAMPAIGN_FAIL_BY_BRAND,
      payload: error.response ? error.response.data.message : error.message
    });
  }
};

export const getCampaignRequestsForBrand = (campaign_id) => async (dispatch) => {
  try {
    dispatch({ type: GET_CAMPAIGN_REQUESTS_FOR_BRAND });

    const { data } = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}/api/v1/my-campaign-requests?campaign_id=${campaign_id}`,
      { withCredentials: true }
    );

    dispatch({ type: GET_CAMPAIGN_REQUESTS_SUCCESS_FOR_BRAND, payload: data });
  } catch (error) {
    dispatch({
      type: GET_CAMPAIGN_REQUESTS_FAIL_FOR_BRAND,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const saveCampaign = (campaignData) => async (dispatch) => {
  try {
    dispatch({ type: SAVE_CAMPAIGN_REQUEST });

    const { data } = await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}/api/v1/save-campaign`,
      campaignData,
      { withCredentials: true }
    );

    dispatch({ type: SAVE_CAMPAIGN_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: SAVE_CAMPAIGN_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const setCampaignIdInRedux = (campaign_id) => {
  return {
    type: SET_CAMPAIGN_ID_IN_REDUX,
    payload: campaign_id,
  };
};
