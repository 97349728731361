import React, { useState } from "react";
import BackArrow from "../assets/BackArrow.svg";
import { PieChart, Pie, Cell } from "recharts";
// import NextArrow from "../assets/NextarrowSVG";
import {
  LineChart,
  Line,
  CartesianGrid as LineCartesian,
  Tooltip,
  Legend as LineLegend,
} from "recharts";
import {
  BarChart,
  Bar,
  CartesianGrid,
  Legend,
  Tooltip as BarTooltip,
} from "recharts";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  Tooltip as AreaTooltip,
} from "recharts";
import "./Engagement.scss";
import { Link } from "react-router-dom";

const engdata = [
  {
    name: "Week 1",
    instagram: 4000,
    youtube: 2400,
  },
  {
    name: "Week 2",
    instagram: 4000,
    youtube: 2400,
  },
  {
    name: "Week 3",
    instagram: 4000,
    youtube: 2400,
  },
  {
    name: "Week 4",
    instagram: 4000,
    youtube: 2400,
  },
  {
    name: "Week 5",
    instagram: 4000,
    youtube: 2400,
  },
  {
    name: "Week 6",
    instagram: 4000,
    youtube: 2400,
  },
  {
    name: "Week 7",
    instagram: 4000,
    youtube: 2400,
  },
];

const areadata = [
  {
    name: "Week 1",
    instagram: 0,
    youtube: 240,
  },
  {
    name: "Week 2",
    instagram: 100,
    youtube: 2400,
  },
  {
    name: "Week 3",
    instagram: 120,
    youtube: 2400,
  },
  {
    name: "Week 4",
    instagram: 180,
    youtube: 2400,
  },
  {
    name: "Week 5",
    instagram: 210,
    youtube: 2400,
  },
  {
    name: "Week 6",
    instagram: 280,
    youtube: 2400,
  },
  {
    name: "Week 7",
    instagram: 320,
    youtube: 2400,
  },
];

const linedata = [
  {
    name: "Week 1",
    reach: 1500,
    engagement: 2400,
  },
  {
    name: "Week 2",
    reach: 10000,
    engagement: 2600,
  },
  {
    name: "Week 3",
    reach: 15000,
    engagement: 3400,
  },
  {
    name: "Week 4",
    reach: 2000,
    engagement: 4200,
  },
  {
    name: "Week 5",
    reach: 25000,
    engagement: 4800,
  },
  {
    name: "Week 6",
    reach: 30000,
    engagement: 5300,
  },
  {
    name: "Week 7",
    reach: 35000,
    engagement: 6000,
  },
];

const piedata = [
  { name: "shorts", value: 0.8 },
  { name: "reels", value: 1.5 },
  { name: "posts", value: 1.3 },
  { name: "stories", value: 0.7 },
  { name: "videos", value: 1.2 },
  { name: "live", value: 0.5 },
];

const COLORS = [
  "#FFB6D9",
  "#EEDFFF",
  "#FEE3EC",
  "#F1CCFB",
  "#CCE6FF",
  "#C7FCEA",
];

const CustomTooltip = ({ active, payload }) => {
  if (active) {
    return (
      <div className="custom-tooltip">
        <p className="tooltip-name">{payload[0].payload.name}</p>
        <p className="tooltip-value">{`${payload[0].value}%`}</p>
      </div>
    );
  }
  return null;
};

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
  index,
}) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text
      x={x}
      y={y}
      fill="#6B4750"
      textAnchor={x > cx ? "start" : "end"}
      dominantBaseline="central"
      fontSize={13}
    >
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};

const Engagement = () => {
  const [selectedCampaign, setSelectedCampaign] = useState("Campaign 1");

  const handleCampaignSelection = (event) => {
    setSelectedCampaign(event.target.value);
  };

  return (
    <div className="engagement-container">
      <div className="current-path">
        <span><Link to="/stats">Insights</Link></span>
        <img src={BackArrow} alt="back-arrow" />
        <span>Engagement</span>
      </div>
      <div className="top-div">
        <div className="heading-bar">
          <p>Engagement Score</p>

          <select value={selectedCampaign} onChange={handleCampaignSelection}>
            <option>Campaign 1</option>
            <option>Campaign 2</option>
            <option>Campaign 3</option>
            <option>Campaign 4</option>
          </select>
        </div>
        <BarChart
          width={1300}
          height={300}
          data={engdata}
          margin={{
            top: 20,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <BarTooltip />
          <Legend />
          <Bar dataKey="youtube" fill="#FFB6D9" />
          <Bar dataKey="instagram" fill="#C3E9F8" />
        </BarChart>
      </div>
      <div className="middle-div">
        <div className="breakdown-container">
          <p>Engagement breakdown by content type</p>
          <PieChart width={400} height={400}>
            <Pie
              data={piedata}
              cx={200}
              cy={180}
              labelLine={false}
              label={renderCustomizedLabel}
              outerRadius={140}
              fill="#8884d8"
              dataKey="value"
            >
              {piedata.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={COLORS[index % COLORS.length]}
                />
              ))}
            </Pie>
            <BarTooltip content={<CustomTooltip />} />
          </PieChart>
        </div>
        <div className="trend-container">
          <p>Engagement Trends Over Time</p>
          <AreaChart
            width={900}
            height={400}
            data={areadata}
            margin={{
              top: 10,
              right: 25,
              left: 10,
              bottom: 15,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis />
            <AreaTooltip />
            <Area
              type="monotone"
              dataKey="instagram"
              stroke="#68C8E4"
              fill="#68C8E4"
            />
          </AreaChart>
        </div>
      </div>
      <div className="bottom-div">
        <p> Reach vs. Engagement</p>
        <LineChart
          width={1300}
          height={300}
          data={linedata}
          margin={{
            top: 15,
            right: 30,
            left: 30,
            bottom: 5,
          }}
        >
          <LineCartesian strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          <LineLegend />
          <Line
            type="monotone"
            dataKey="reach"
            stroke="#68E484"
            strokeWidth={3}
            activeDot={{ r: 8 }}
            width={10}
          />
          <Line
            type="monotone"
            strokeWidth={3}
            dataKey="engagement"
            stroke="#68C8E4"
          />
        </LineChart>
      </div>
    </div>
  );
};

export default Engagement;
