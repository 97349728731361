import React, { useState } from "react";
import "./Youtube.scss";
import BackArrow from "../assets/BackArrow.svg";
import LineChartComponent from "./LineChart";
import PieChartComponent from "./PieChart";
import BarChartComponent from "./BarChart";
import Video from "../assets/Video.svg";
import Shorts from "../assets/Shorts.svg";
import Live_stream from "../assets/Live_stream.svg";
import { useLocation } from "react-router";
import { Link } from "react-router-dom";

const engagementData = [
  { name: "Comments", value: 400 },
  { name: "Likes", value: 300 },
  { name: "Shares", value: 300 },
  { name: "Saves", value: 200 },
];
// const COLORS = ["#B3EBE8", "#FDD5DC", "#D0FCD7", "#B9F3FC"];

const campaignData = [
  { type: "Videos", value: 4000, fill: "#C3E9F8" },
  { type: "Shorts", value: 3000, fill: "#D0FCD7" },
  { type: "Live_Stream", value: 2000, fill: "#F8B4BD" },
];

const Reachdata = [
  { name: "January", shorts: 4000, live_stream: 2400, video: 2400, amt: 2400 },
  { name: "February", shorts: 3000, live_stream: 1398, video: 2400, amt: 2210 },
  { name: "March", shorts: 2000, live_stream: 9800, video: 400, amt: 2290 },
  { name: "April", shorts: 2780, live_stream: 3908, video: 400, amt: 2000 },
  { name: "May", shorts: 1890, live_stream: 4800, video: 200, amt: 2181 },
  { name: "June", shorts: 2390, live_stream: 3800, video: 400, amt: 2500 },
  { name: "July", shorts: 3490, live_stream: 4300, video: 400, amt: 2100 },
];

function Youtube() {
  const location = useLocation();
  const { platform } = location.state || {};
  const [selectedMetric, setSelectedMetric] = useState("Comments");
  const [selectedPlatform, setSelectedPlatform] = useState(platform);

  const handleMetricChange = (event) => {
    setSelectedMetric(event.target.value);
  };

  return (
    <div className="youtube-insights">
      <div className="youtube-insights-content">
        <div className="current-path">
          <span><Link to='/stats'>Insights</Link></span>
          <img src={BackArrow} alt="back-arrow" />
          {selectedPlatform === "YouTube" && <span>Youtube</span>}
          {selectedPlatform === "Instagram" && <span>Instagram</span>}
        </div>
        <div className="states">
          <div className="first-container">
            <div className="engagement">
              <div className="options-content">
                <span className="platformtitle">Engagement</span>
                <div className="select-wrapper">
                  <select value={selectedMetric} onChange={handleMetricChange}>
                    <option value="Comments">Comments</option>
                    <option value="Likes">Likes</option>
                    <option value="Shares">Shares</option>
                    <option value="Saves">Saves</option>
                  </select>
                </div>

              </div>
              <div class="pie-chart">
                <PieChartComponent
                  data={engagementData}
                  type={selectedPlatform}
                />
              </div>
            </div>
            <div className="campaign-posted">
              <div className="options-content">
                <span className="platformtitle">Content Posted</span>
                <select value={selectedMetric} onChange={handleMetricChange}>
                  <option value="Comments">Comments</option>
                  <option value="Likes">Likes</option>
                  <option value="Shares">Shares</option>
                  <option value="Saves">Saves</option>
                </select>
              </div>
              <div className="bar-chart">
                <BarChartComponent data={campaignData} type={selectedPlatform} />
              </div>
            </div>
          </div>
          <div className="second-container">
            <div className="options-content">
              {selectedPlatform === "YouTube" && <span className="platformtitle">Total Reach of the Brand on YouTube</span>}
              {selectedPlatform === "Instagram" && <span className="platformtitle">Total Reach of the Brand on Instagram</span>}
              {selectedPlatform === "YouTube" && <div className="platform-icon">
                <div className="labels">
                  <img src={Shorts} alt=""></img>
                  <span>Shorts</span>
                </div>
                <div className="labels">
                  <img src={Video} alt=""></img>
                  <span>Video</span>
                </div>
                <div className="labels">
                  <img src={Live_stream} alt=""></img>
                  <span>Live_Stream</span>
                </div>

              </div>}

              {selectedPlatform === "Instagram" && (
                <div className="platform-icon">
                  <div className="labels">
                    <img src={Shorts} alt="Shorts"></img>
                    <span>Reels</span>
                  </div>
                  <div className="labels">
                    <img src={Video} alt="Video"></img>
                    <span>Stories</span>
                  </div>
                  <div className="labels">
                    <img src={Live_stream} alt="Live stream"></img>
                    <span>Posts</span>
                  </div>
                </div>
              )}
            </div>
            <div className="line-chart">
              <LineChartComponent data={Reachdata} type={selectedPlatform} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Youtube;
