import React from "react";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
} from "recharts";

const Evolutiondata = [
  {
    name: "January",
    activity: 40,
  },
  {
    name: "February",
    activity: 50,
  },
  {
    name: "March",
    activity: 70,
  },
  {
    name: "April",
    activity: 80,
  },
  {
    name: "May",
    activity: 100,
  },
  {
    name: "June",
    activity: 150,
  },
  {
    name: "July",
    activity: 180,
  },
  {
    name: "August",
    activity: 200,
  },
  {
    name: "September",
    activity: 230,
  },
  {
    name: "October",
    activity: 240,
  },
  {
    name: "November",
    activity: 280,
  },
  {
    name: "December",
    activity: 300,
  },
];

export default function EvolutionAreaChart() {
  return (
    <AreaChart
      width={1500}
      height={400}
      data={Evolutiondata}
      margin={{
        top: 10,
        right: 30,
        left: 0,
        bottom: 0,
      }}
    >
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="name" />
      <YAxis />
      <Tooltip />
      <Area
        type="monotone"
        dataKey="activity"
        stroke="#68C8E4"
        strokeWidth={3}
        fill="rgba(104, 200, 228, 0.30)"
      />
    </AreaChart>
  );
}
