import axios from 'axios';
import {
  LOGIN_SEND_OTP_PHONE_REQUEST,
  LOGIN_SEND_OTP_PHONE_SUCCESS,
  LOGIN_SEND_OTP_PHONE_FAIL,
  LOGIN_VERIFY_OTP_PHONE_REQUEST,
  LOGIN_VERIFY_OTP_PHONE_SUCCESS,
  LOGIN_SEND_OTP_EMAIL_REQUEST,
  LOGIN_SEND_OTP_EMAIL_SUCCESS,
  LOGIN_SEND_OTP_EMAIL_FAIL,
  LOGIN_VERIFY_OTP_EMAIL_REQUEST,
  LOGIN_VERIFY_OTP_EMAIL_SUCCESS,
  LOGIN_VERIFY_OTP_EMAIL_FAIL,
  REGISTER_EMAIL_REQUEST,
  REGISTER_EMAIL_SUCCESS,
  REGISTER_EMAIL_FAIL,
  VERIFY_EMAIL_OTP_REQUEST,
  VERIFY_EMAIL_OTP_SUCCESS,
  VERIFY_EMAIL_OTP_FAIL,
  REGISTER_PHONE_REQUEST,
  REGISTER_PHONE_SUCCESS,
  REGISTER_PHONE_FAIL,
  VERIFY_PHONE_OTP_REQUEST,
  VERIFY_PHONE_OTP_SUCCESS,
  VERIFY_PHONE_OTP_FAIL,
  SET_EMAIL_IN_REDUX,
  LOAD_USER_REQUEST,
  LOAD_USER_SUCCESS,
  LOAD_USER_FAIL,
  LOGOUT_SUCCESS,
  LOGOUT_FAIL,
} from '../constants/userConstants';


//load user
export const loadUser = () => async (dispatch) => {
  try {
    dispatch({ type: LOAD_USER_REQUEST });

    const { data } = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/v1/me`,{ withCredentials: true });

    dispatch({ type: LOAD_USER_SUCCESS, payload: data.user });
  } catch (error) {
    dispatch({ type: LOAD_USER_FAIL, payload: error.response.data.message });
  }
};

// Logout User
export const logoutUser = () => async (dispatch) => {
  try {
    await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/v1/logout`,{ withCredentials: true });

    dispatch({ type: LOGOUT_SUCCESS });
  } catch (error) {
    dispatch({ type: LOGOUT_FAIL, payload: error.response.data.message });
  }
};

//login using email
export const sendOtpEmail = (emailData) => async (dispatch) => {
  try {
    dispatch({ type: LOGIN_SEND_OTP_EMAIL_REQUEST });

    const { data, status } = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/v1/email-login`, emailData, {
      withCredentials: true,
    });

    if (status === 404) {
      dispatch({ type: LOGIN_SEND_OTP_EMAIL_FAIL, payload: data.message });
    } else {
      dispatch({ type: LOGIN_SEND_OTP_EMAIL_SUCCESS, payload: data });
    }
  } catch (error) {
    dispatch({
      type: LOGIN_SEND_OTP_EMAIL_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

//verify otp for login using email
export const verifyEmailOtp = (otpData) => async (dispatch) => {
  try {
    dispatch({ type: LOGIN_VERIFY_OTP_EMAIL_REQUEST });

    const { data } = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/v1/email-login-verify`, otpData,{
      withCredentials: true,
    });

    dispatch({ type: LOGIN_VERIFY_OTP_EMAIL_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: LOGIN_VERIFY_OTP_EMAIL_FAIL,
      payload: error.response && error.response.data.message ? error.response.data.message : error.message,
    });
  }
};


export const setEmailInRedux = (email) => ({
  type: SET_EMAIL_IN_REDUX,
  payload: email,
});

//send otp for login using phone
export const sendOtp = (otpData) => async (dispatch) => {
  try {
    dispatch({ type: LOGIN_SEND_OTP_PHONE_REQUEST });

    const { data } = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/v1/phone-login`, otpData, {
      withCredentials: true,
    });

    dispatch({ type: LOGIN_SEND_OTP_PHONE_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: LOGIN_SEND_OTP_PHONE_FAIL,
      payload: error.response && error.response.data.message ? error.response.data.message : error.message,
    });
  }
};

//verify otp for login using phone
export const verifyPhoneOtp = (otpData) => async (dispatch) => {
  try {
    dispatch({ type: LOGIN_VERIFY_OTP_PHONE_REQUEST });

    const { data } = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/v1/phone-login-verify`, otpData, {
      withCredentials: true,
    });

    dispatch({ type: LOGIN_VERIFY_OTP_PHONE_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: LOGIN_SEND_OTP_PHONE_FAIL,
      payload: error.response && error.response.data.message ? error.response.data.message : error.message,
    });
  }
};

//register using email
export const registerEmail = (email) => async (dispatch) => {
  dispatch({ type: REGISTER_EMAIL_REQUEST});
  try {
    const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/v1/send-email-otp`,email,{
      withCredentials: true,
    });
    dispatch({ type: REGISTER_EMAIL_SUCCESS, payload: response.data });
  } catch (error) {
    dispatch({ type: REGISTER_EMAIL_FAIL, payload: error.message});
  }
};

//verify otp for register using email
export const verifyEmailOTP = (otp) => async (dispatch) => {
  dispatch({ type: VERIFY_EMAIL_OTP_REQUEST});
  try {
    const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/v1/verify-email-otp`, otp,{
      withCredentials: true,
    });
    dispatch({ type: VERIFY_EMAIL_OTP_SUCCESS, payload: response.data });
  } catch (error) {
    dispatch({ type: VERIFY_EMAIL_OTP_FAIL, payload: error.message});
  }
};

//register using phone
export const registerPhone = (phone_no) => async (dispatch) => {
  dispatch({ type: REGISTER_PHONE_REQUEST});
  
  try {
    const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/v1/send-otp-phone`,phone_no,{
      withCredentials: true,
    });
    dispatch({ type: REGISTER_PHONE_SUCCESS, payload: response.data});
  } catch (error) {
    dispatch({ type: REGISTER_PHONE_FAIL, payload: error.message});
  }
};

//verify otp for register using phone
export const verifyPhoneOTP = (otp) => async (dispatch) => {
  dispatch({ type: VERIFY_PHONE_OTP_REQUEST});
  try {
    const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/v1/verify-phone-otp`,otp,{
      withCredentials: true,
    });
    dispatch({ type: VERIFY_PHONE_OTP_SUCCESS, payload: response.data});
  } catch (error) {
    dispatch({ type: VERIFY_PHONE_OTP_FAIL , payload: error.message});
  }
};

