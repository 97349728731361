import React, { useState } from 'react'
import BackArrow from '../assets/BackArrow.svg'
import LineChartComponent from './LineChart'
import PieChartComponent from './PieChart'
import BarChartComponent from './BarChart'
import HorizontalBarChartComponent from './HorizontelBarChart'
import Video from '../assets/Video.svg'
import Shorts from '../assets/Shorts.svg'
import './Reach.scss';
import { Link } from 'react-router-dom'

const contenttypeData = [
    { name: 'Videos', value: 4000 ,fill: '#C3E9F8'},
    { name: 'Shorts', value: 3000 ,fill: '#d0fcd7'},
    { name: 'Live_Stream', value: 2000 ,fill: '#F8B4BD'},
    { name: 'reels', value: 2780 ,fill: '#abcdef'},
    { name: 'Posts', value: 1890 ,fill: '#FDD5DC'},
    { name: 'Stories', value: 2390 ,fill: '#fedcba'}
]

const reachonplatformData = [
    { name: 'YouTube', value: 4000 },
    { name: 'Instagram', value: 3000 },
];

const topCampaignData = [
    { type: 'Campaign 1', value: 4000 ,fill: '#C3E9F8'},
    { type: 'Campaign 2', value: 3000 ,fill: '#d0fcd7'},
    { type: 'Campaign 3', value: 2000 ,fill: '#F8B4BD'},
    { type: 'Campaign 4', value: 2780 ,fill: '#abcdef'},
    { type: 'Campaign 5', value: 1890 ,fill: '#FDD5DC'},
    { type: 'Campaign 6', value: 2390 ,fill: '#fedcba'}
];

const reachoverTimeData = [
    { name: 'January', instagram: 4000, youtube: 2400, amt: 2400 },
    { name: 'February', instagram: 3000, youtube: 1398, amt: 2210 },
    { name: 'March', instagram: 2000, youtube: 9800, amt: 2290 },
    { name: 'April', instagram: 2780, youtube: 3908, amt: 2000 },
    { name: 'May', instagram: 1890, youtube: 4800, amt: 2181 },
    { name: 'June', instagram: 2390, youtube: 3800, amt: 2500 },
    { name: 'July', instagram: 3490, youtube: 4300, amt: 2100 },
    { name: 'August', instagram: 3490, youtube: 4300, amt: 2100 },
];

const Reach = () => {
    const [selectedMetric, setSelectedMetric] = useState("Comments");


    const handleMetricChange = (event) => {
        setSelectedMetric(event.target.value);
    };

    return (
        <div className="reach-insights">
            <div className='reach-insights-content'>
                <div className="current-path">
                    <span><Link to='/stats'>Insights</Link></span>
                    <img src={BackArrow} alt="back-arrow" />
                    <span>Reach</span>
                </div>

                <div className="states">
                    <div className="first-container">
                        <div className="options-content">
                            <span className='platformtitle'>Reach over time</span>
                            <div className='platform-icon'>
                                <div className='labels'>
                                    <img src={Video} alt="video" />
                                    <span>YouTube</span>
                                </div>
                                <div className='labels'>
                                    <img src={Shorts} alt="shorts" />
                                    <span>Instagram</span>
                                </div>
                            </div>
                            <select value={selectedMetric} onChange={handleMetricChange}>
                                <option>Monthly Reach</option>
                                <option>Weekly Reach</option>
                                <option>Daily Reach</option>
                                <option>Yearly Reach</option>
                            </select>
                        </div>
                        <div className='line-chart'>
                            <LineChartComponent data={reachoverTimeData} />
                        </div>
                    </div>

                    <div className="second-container">
                        <div className='reach-on-platform'>
                            <div className="options-content">
                                <span className='platformtitle'>Reach on Platforms</span>
                            </div>
                            <div className='pie-chart'>
                                <PieChartComponent data={reachonplatformData}/>
                            </div>
                        </div>
                        <div className='top-performing-campaigns'>
                            <div className="options-content">
                                <span className='platformtitle'>Top Performing Campaigns (by Reach)</span>
                            </div>
                            <div className='bar-chart'>
                                <BarChartComponent data={topCampaignData} />
                            </div>
                        </div>
                    </div>

                    <div className="third-container">
                        <div className="options-content">
                            <span className='platformtitle'>Reach over time</span>
                        </div>
                        <div className='horizontal-bar-chart'>
                            <HorizontalBarChartComponent data={contenttypeData}/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Reach
