// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loading {
  width: 100vw;
  height: 100vh;
  background-color: white;
  display: grid;
  place-items: center;
  max-width: 100%;
}

.loading > div {
  width: 10vmax;
  height: 10vmax;
  border-bottom: 5px solid rgba(0, 0, 0, 0.719);
  border-radius: 50%;
  animation: loadingRotate 800ms linear infinite;
}

@keyframes loadingRotate {
  to {
    transform: rotateZ(-360deg);
  }
}`, "",{"version":3,"sources":["webpack://./src/components/layout/Loader/Loader.scss"],"names":[],"mappings":"AAAA;EACI,YAAA;EACA,aAAA;EACA,uBAAA;EACA,aAAA;EACA,mBAAA;EACA,eAAA;AACJ;;AAEE;EACE,aAAA;EACA,cAAA;EACA,6CAAA;EAEA,kBAAA;EAEA,8CAAA;AADJ;;AAIE;EACE;IACE,2BAAA;EADJ;AACF","sourcesContent":[".loading {\n    width: 100vw;\n    height: 100vh;\n    background-color: white;\n    display: grid;\n    place-items: center;\n    max-width: 100%;\n  }\n  \n  .loading > div {\n    width: 10vmax;\n    height: 10vmax;\n    border-bottom: 5px solid rgba(0, 0, 0, 0.719);\n  \n    border-radius: 50%;\n  \n    animation: loadingRotate 800ms linear infinite;\n  }\n  \n  @keyframes loadingRotate {\n    to {\n      transform: rotateZ(-360deg);\n    }\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
