import React, { useEffect, useState } from "react";
import "./Brandsignup.scss";
import { Link, useNavigate, useParams } from "react-router-dom";
import Badge from "../assets/Badge.svg";
import GoogleIcon from "../assets/google 1.svg";
import Flag from "../assets/Flag Of India.svg";
import UserIcon from "../assets/User.svg";
import { MuiOtpInput } from "mui-one-time-password-input";
import {
  loadUser,
  registerEmail,
  registerPhone,
  setEmailInRedux,
  verifyEmailOTP,
  verifyPhoneOTP,
} from "../../actions/userAction";
import { useDispatch, useSelector } from "react-redux";
import {
  VERIFY_EMAIL_OTP_RESET,
  VERIFY_PHONE_OTP_RESET,
} from "../../constants/userConstants";
import Loader from "../layout/Loader/Loader";

const LoginOptions = ({ onClick, role }) => {
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const isEmailOtpSuccess = useSelector((state) => state.user?.user?.isEmailOtpSuccess);
  const isLoading = useSelector((state) => state.user.loading);

  const handleSignUp = () => {
    dispatch(setEmailInRedux(email)); 
    dispatch(registerEmail({ email }));
  };

  const googleAuth = (e) => {
    e.preventDefault();
    const roleString = typeof role === "string" ? role : "influencer";

    window.open(
      `http://localhost:5000/auth/google?role=${roleString}`,
      "_self"
    );
  };

  useEffect(() => {
    if (isEmailOtpSuccess) {
      onClick("EmailOTP", email);
    }
  }, [isEmailOtpSuccess, onClick, dispatch, email]);

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <div className="login-page">
          <div className="logo">
            {role === "influencer" ? (
              <>
                <img src={UserIcon} alt="User Logo" />
                <span className="brand-text">Influencer</span>
              </>
            ) : (
              <>
                <img src={Badge} alt="Logo" />
                <span className="brand-text">Brand</span>
              </>
            )}
          </div>
          <div className="brand-header-text">
            <h1>Hi, savvy </h1>
            <h1>marketer!</h1>
          </div>

          <button className="google-button" onClick={googleAuth}>
            <img src={GoogleIcon} alt="Google Logo" className="google-logo" />
            Continue with Google
          </button>

          <div className="separator">
            <span></span>
            <p className="separator-text">OR</p>
            <span></span>
          </div>

          <div className="email-input-box">
            <input
              placeholder="Your E-mail Address"
              className="signup-email-input"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <button onClick={handleSignUp}>Sign Up for Free</button>
          </div>

          <div className="bottom-text">
            <p>
              Already Have an Account? <a href="/login">Log In Here</a>
            </p>
            <p>
              {role === "influencer" ? (
                <>
                  Not an Influencer?{" "}
                  <Link to="/sign-up/brand">Sign up as a Brand</Link>
                </>
              ) : (
                <>
                  Not a Brand?{" "}
                  <Link to="/sign-up/influencer">Sign up as an Influencer</Link>
                </>
              )}
            </p>
          </div>
        </div>
      )}
    </>
  );
};

const BrandEmailOTP = ({ onClick, role }) => {
  const dispatch = useDispatch();
  const [otp, setOtp] = useState("");
  const {isOtpVerifiedSuccessfully} = useSelector((state) => state.user || false);
  const isLoading = useSelector((state) => state.user.loading);
  const userEmail = useSelector((state) => state.email?.userEmail); 

  useEffect(() => {
    if (isOtpVerifiedSuccessfully) {
      onClick("B");
    }
  }, [isOtpVerifiedSuccessfully, onClick, dispatch]);

  const handleVerifyOTP = () => {
    dispatch(verifyEmailOTP({ otp }));
  };

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <div className="login-page">
          <div className="logo">
            {role === "influencer" ? (
              <>
                <img src={UserIcon} alt="User Logo" />
                <span className="brand-text">Influencer</span>
              </>
            ) : (
              <>
                <img src={Badge} alt="Logo" />
                <span className="brand-text">Brand</span>
              </>
            )}
          </div>

          <div className="brand-header-text">
            <h1>Hi, savvy </h1>
            <h1>marketer!</h1>
          </div>

          <div className="email-signup-box">
            <label>Your E-mail Address</label>
            <input className="email-input" value={userEmail} readOnly />
            <label>Enter OTP</label>
            <input
              className="email-input"
              value={otp}
              onChange={(e) => setOtp(e.target.value)}
            />
          </div>

          <button onClick={handleVerifyOTP} className="signup-button">
            Submit
          </button>

          <div className="bottom-text">
            <p>
              Already Have an Account? <a href="/login">Log In Here</a>
            </p>
            <p>
              {role === "influencer" ? (
                <>
                  Not an Influencer?{" "}
                  <Link to="/signup-brand">Sign up as a Brand</Link>
                </>
              ) : (
                <>
                  Not a Brand?{" "}
                  <Link to="/signup-influencer">Sign up as an Influencer</Link>
                </>
              )}
            </p>
          </div>
        </div>
      )}
    </>
  );
};

const PhoneDetails = ({ onClick, role }) => {
  const dispatch = useDispatch();
  const [phoneNumber, setPhoneNumber] = useState("");
  const {isOtpSendForMobile} = useSelector((state) => state.user ||false);
  const isLoading = useSelector((state) => state.user.loading);

  useEffect(() => {
    if (isOtpSendForMobile) {
      onClick("C");
    }
  }, [isOtpSendForMobile, onClick]);

  const formatPhoneNumber = (number) => {
    const formattedNumber = `+91${number}`;
    return formattedNumber;
  };

  const handleGenerateOTP = () => {
    const phone_no = formatPhoneNumber(phoneNumber);
    dispatch(registerPhone({ phone_no }));
  };

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <div className="login-page">
          <div className="logo">
            {role === "influencer" ? (
              <>
                <img src={UserIcon} alt="User Logo" />
                <span className="brand-text">Influencer</span>
              </>
            ) : (
              <>
                <img src={Badge} alt="Logo" />
                <span className="brand-text">Brand</span>
              </>
            )}
          </div>

          <div className="brand-header-text">
            <h1>Hi, savvy </h1>
            <h1>marketer!</h1>
          </div>
          <div className="phone-login">
            <div className="phone-detail-box">
              <img src={Flag} alt="flag-logo" id="flag-logo" />
              <input type="text" id="country-code" value="+91" readOnly />
              <input
                type="text"
                placeholder="Mobile No."
                id="phone-number-signup"
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
              />
            </div>
            <div className="signup-check-box">
              <input className="signup-checkbox" type="checkbox"></input>
              <label>Remember Me</label>
            </div>
            <button id="generate-otp-button" onClick={handleGenerateOTP}>
              Generate OTP
            </button>
          </div>

          <div className="bottom-text">
            <p>
              Already Have an Account? <a href="/login">Log In Here</a>
            </p>
            <p>
              {role === "influencer" ? (
                <>
                  Not an Influencer?{" "}
                  <Link to="/signup-brand">Sign up as a Brand</Link>
                </>
              ) : (
                <>
                  Not a Brand?{" "}
                  <Link to="/signup-influencer">Sign up as an Influencer</Link>
                </>
              )}
            </p>
          </div>
        </div>
      )}
    </>
  );
};

const OtpPage = ({ onClick, role }) => {
  const dispatch = useDispatch();
  const [otp, setOtp] = useState(Array.from({ length: 6 }, () => ""));
  const isLoading = useSelector((state) => state.user.loading);
  const { isOtpVerifiedForMobile} = useSelector((state) => state.user || false);

  const navigate = useNavigate();

  const handleVerifyOTP = async() => {
    await dispatch(verifyPhoneOTP({otp, role}));
    dispatch(loadUser());
  };

  useEffect(() => {
    if (isOtpVerifiedForMobile) {
      navigate("/dashboard");
    }
  },[isOtpVerifiedForMobile]);

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <div className="login-page">
          <div className="logo">
            {role === "influencer" ? (
              <>
                <img src={UserIcon} alt="User Logo" />
                <span className="brand-text">Influencer</span>
              </>
            ) : (
              <>
                <img src={Badge} alt="Logo" />
                <span className="brand-text">Brand</span>
              </>
            )}
          </div>

          <div className="brand-header-text">
            <h1>Hi, savvy </h1>
            <h1>marketer!</h1>
          </div>
          <div className="phone-login">
            <div>
              <MuiOtpInput
                value={otp}
                onChange={setOtp}
                length={6}
                separator={<span>-</span>}
                isInputNum
              />
            </div>
            <div className="user-pref-box">
              <div className="signup-check-box">
                <input className="signup-checkbox" type="checkbox"></input>
                <label>Remember Me</label>
              </div>

              <p onClick={() => onClick("B")} className="change-phone-number">
                Change Phone No.
              </p>
            </div>

            <button id="generate-otp-button" onClick={handleVerifyOTP}>
              Validate and Sign Up
            </button>
          </div>

          <div className="bottom-text">
            <p>
              Already Have an Account? <a href="/login">Log In Here</a>
            </p>
            <p>
              {role === "influencer" ? (
                <>
                  Not an Influencer?{" "}
                  <Link to="/signup-brand">Sign up as a Brand</Link>
                </>
              ) : (
                <>
                  Not a Brand?{" "}
                  <Link to="/signup-influencer">Sign up as an Influencer</Link>
                </>
              )}
            </p>
          </div>
        </div>
      )}
    </>
  );
};

const Brandsignup = () => {
  const [currentComponent, setCurrentComponent] = useState("A");
  const { role } = useParams();
  const [userEmail, setUserEmail] = useState("");

  const handleButtonClick = (component, email = "") => {
    setCurrentComponent(component);
    setUserEmail(email);
  };

  return (
    <div className="home-div">
      {currentComponent === "A" && (
        <LoginOptions role={role} onClick={handleButtonClick} />
      )}
      {currentComponent === "B" && (
        <PhoneDetails role={role} onClick={handleButtonClick} />
      )}
      {currentComponent === "C" && (
        <OtpPage role={role} onClick={handleButtonClick} />
      )}

      {currentComponent === "EmailOTP" && (
        <BrandEmailOTP
          role={role}
          onClick={handleButtonClick}
          userEmail={userEmail}
        />
      )}
    </div>
  );
};

export default Brandsignup;
