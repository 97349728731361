import React, { useEffect } from "react";
import { useState } from "react";
import Conversations from "./Conversations";
import Messages from "./Messages";
import "./Chats.scss";
import { io } from 'socket.io-client'

function Chats() {
  const [selectedConversation, setSelectedConversation] = useState(null);
  const [socket, setSocket] = useState(null)

  const handleSelectConversation = (conversation) => {
    setSelectedConversation(conversation);
  };


  useEffect(() => {
		setSocket(io('http://localhost:8080'))
	}, [])

  return (
    <div className="chats-container">
      <div className="conversations-container">
        <Conversations onSelectConversation={handleSelectConversation} socket={socket} />
      </div>
      <div className="messages-container">
        <Messages conversation={selectedConversation} socket={socket}/>
      </div>
    </div>
  );
}

export default Chats;
