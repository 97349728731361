import React, { useEffect, useRef } from 'react'
import { Chart } from 'chart.js/auto'


const COLORS = ['#DF567C', '#68C8E4'];

const LineChartComponent = ({ data }) => {

  const chartContainer = useRef(null);

  useEffect(() => {
    if (chartContainer && chartContainer.current) {
      const ctx = chartContainer.current.getContext('2d');

      new Chart(ctx, {
        type: 'line',
        data: {
          labels: data.map(entry => entry.name),
          datasets: [
            {
              label: 'instargram',
              data: data.map(entry => entry.instagram),
              borderColor: COLORS[0],
              backgroundColor: 'transparent',
              pointRadius: 4,
              pointHoverRadius: 6,
              borderWidth: 2,
            },
            {
              label: 'youtube',
              data: data.map(entry => entry.youtube),
              borderColor: COLORS[1],
              backgroundColor: 'transparent',
              pointRadius: 4,
              pointHoverRadius: 6,
              borderWidth: 2,
            },
          ],
        },
        options: {
          responsive: true,
          plugins: {
            legend: {
              display: false,
            },
          },
          scales: {
            x: {
              display: true,
              title: {
                display: false,
              },
            },
            y: {
              display: true,
              title: {
                display: false,
              },
            },
          },
        },
      });
    }
  }, [data]);

  return (
    <div style={{ width: '100%', height: '95%' }}>
      <canvas ref={chartContainer} style={{ width: '100%', height: '90%' }}/>
    </div>  
  );
}
export default LineChartComponent;
