import React, { useEffect } from "react";
import "./ProfileSection.scss";
import BrandProfile from "../BrandProfile/BrandProfile";
import ContactDetails from "../ContactDetails/ContactDetails";
import { useDispatch, useSelector } from "react-redux";
import { getProfileDetailsForBrand } from "../../actions/profileAction";
import {
  UPDATE_BRAND_CONTACT_INFO_RESET,
  UPDATE_BRAND_PROFILE_INFO_RESET,
} from "../../constants/profileConstants";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import MuiLoader from "../layout/Loader/MuiLoader";

function ProfileSection() {
  const dispatch = useDispatch();
  const { brandProfile, isUpdated, loading } = useSelector(
    (state) => state?.profile
  );

  useEffect(() => {
    dispatch(getProfileDetailsForBrand());

    if (isUpdated) {
      toast.success("Profile updated successfully", {
        position: "top-right",
        autoClose: 3000,
        bodyClassName: "custom-toast-body",
      });

      dispatch(getProfileDetailsForBrand());

      dispatch({ type: UPDATE_BRAND_PROFILE_INFO_RESET });
      dispatch({ type: UPDATE_BRAND_CONTACT_INFO_RESET });
    }
  }, [dispatch, isUpdated]);
  return (
    <>
      {loading ? (
        <MuiLoader />
      ) : (
        <div className="profile-section-container">
          <BrandProfile brandProfile={brandProfile} />
          <ContactDetails brandProfile={brandProfile} />
          <ToastContainer />
        </div>
      )}
    </>
  );
}

export default ProfileSection;
