import React from "react";
import { Dialog, DialogTitle, DialogContent, Button } from "@mui/material";
import SentGIF from "../assets/paperplane.gif";
function ConfirmationPopup({ open, onClose, message, withNote }) {
  const handleClose = () => {
    onClose();
  };
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="sm"
      fullWidth
      style={{ fontFamily: "Poppins", borderRadius: "10px" }}
    >
      <DialogTitle style={{ textAlign: "right", paddingRight: "8px" }}>
        <Button
          onClick={handleClose}
          color="primary"
          style={{ padding: 0, boxShadow: "none", width: "fit-content" }}
        >
          &#10005;
        </Button>
      </DialogTitle>
      <DialogContent>
        {withNote ? (
          <>
            <DialogContent>
              <div style={{ textAlign: "center", fontFamily: "Poppins" }}>
                <img
                  src={SentGIF}
                  alt="Loading GIF"
                  style={{ height: "4rem", margin: "0.7rem" }}
                />
                <div style={{ color: "#e43a67", fontSize: "1.2rem" }}>
                  Collaboration Request Sent with Your Note
                </div>
                <div style={{ marginTop: "1rem", color: "#333" }}>
                  Your Note
                </div>
                <div style={{ color: "#888" }}>{message}</div>
              </div>
            </DialogContent>
          </>
        ) : (
          <DialogContent>
            <div style={{ textAlign: "center", fontFamily: "Poppins" }}>
              <img
                src={SentGIF}
                alt="Loading GIF"
                style={{ height: "5.5rem", margin: "0.7rem" }}
              />
              <div style={{ color: "#e43a67", fontSize: "1.2rem" }}>
                Collaboration Request Sent Without Any Note
              </div>
            </div>
          </DialogContent>
        )}
      </DialogContent>
    </Dialog>
  );
}

export default ConfirmationPopup;
