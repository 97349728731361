import React, { useState } from "react";
import "./Campaign.scss";
import CampaignIllustration from "../assets/Campaign default page illustration.svg";
import DashboardIcon from "../assets/Create campaign icon.svg";
import Dashboard from "../assets/Dashboard.svg";
import { useNavigate } from "react-router-dom";
import SearchIcon from "../assets/Search.svg";
import Vector from "../assets/Vector.svg";

function Campaign() {
  const navigate = useNavigate();

  const goToCreatCampaign = () => {
    navigate("/campaign/create");
  };

  return (
    <div className="campaign-main-page">
      <div className="filters">
        <div className="search">
          <img src={SearchIcon} alt="Search Icon" className="search-icon" />
          <input
            className="search-bar"
            type="text"
            placeholder="Search for Campaigns"
          />
        </div>
        <div className="createCampaign">
          <span onClick={() => goToCreatCampaign()}>Create Campaign</span>
          <img src={Dashboard} alt="Dashboard Icon" />
        </div>
        <div className="dashboard">
          <span>Filters</span>
          <img src={Vector} alt="Vector Icon" />
        </div>
      </div>
      <div className="campaign-header">Recently Created Campaigns</div>
      <div className="campaign-content">
        <img
          src={CampaignIllustration}
          alt="Campaign"
          className="campaign-img"
        />
        <h2>Welcome to the Campaign!</h2>
        <p>It looks like you haven't created any campaigns yet.</p>
        <p>Ready to launch your first one?</p>
        <button onClick={() => goToCreatCampaign()}>
          Create Campaign <img src={DashboardIcon} alt="dashboardpng" />
        </button>
      </div>
    </div>
  );
}

export default Campaign;
