import React, { useEffect, useState } from "react";
import "./Phone_Sign_Up.scss";
import { Link, useNavigate, useParams } from "react-router-dom";
import Badge from "../assets/Badge.svg";
import GoogleIcon from "../assets/google 1.svg";
import Flag from "../assets/Flag Of India.svg";
import UserIcon from "../assets/User.svg";
import {setEmailForSignUp, setEmailInRedux} from "../../actions/userAction";
import { MuiOtpInput } from "mui-one-time-password-input";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../layout/Loader/Loader";
import {
    registerPhone,
    verifyPhoneOTP,
  } from "../../actions/userAction";
import axios from "axios";

const PhoneDetails = ({ onClick, role }) => {
    const dispatch = useDispatch();
    const [phoneNumber, setPhoneNumber] = useState("");
    const phoneRegistrationSuccess = useSelector((state) => state.user.success);
    const isLoading = useSelector((state) => state.user.loading);

    useEffect(() => {
      if (phoneRegistrationSuccess) {
        onClick("C");
      }
    }, [phoneRegistrationSuccess, onClick]);
  
    const formatPhoneNumber = (number) => {
      const formattedNumber = `+91${number}`;
      return formattedNumber;
    };
  
    const handleGenerateOTP = () => {
      const phone_no = formatPhoneNumber(phoneNumber);
      dispatch(registerPhone({ phone_no}));
    };
  
    return (
      <>
        {isLoading ? (
          <Loader />
        ) : (
          <div className="login-page">
            <div className="logo">
              {role === "influencer" ? (
                <>
                  <img src={UserIcon} alt="User Logo" />
                  <span className="brand-text">Influencer</span>
                </>
              ) : (
                <>
                  <img src={Badge} alt="Logo" />
                  <span className="brand-text">Brand</span>
                </>
              )}
            </div>
  
            <div className="brand-header-text">
              <h1>Hi, savvy </h1>
              <h1>marketer!</h1>
            </div>
            <div className="phone-login">
              <div className="phone-detail-box">
                <img src={Flag} alt="flag-logo" id="flag-logo" />
                <input type="text" id="country-code" value="+91" readOnly />
                <input
                  type="text"
                  placeholder="Mobile No."
                  id="phone-number-signup"
                  value={phoneNumber}
                  onChange={(e) => setPhoneNumber(e.target.value)}
                />
              </div>
              <div className="signup-check-box">
                <input className="signup-checkbox" type="checkbox"></input>
                <label>Remember Me</label>
              </div>
              <button id="generate-otp-button" onClick={handleGenerateOTP}>
                Generate OTP
              </button>
            </div>
  
            <div className="bottom-text">
              <p>
                Already Have an Account? <a href="/login">Log In Here</a>
              </p>
              <p>
                {role === "influencer" ? (
                  <>
                    Not an Influencer?{" "}
                    <Link to="/sign-up/brand">Sign up as a Brand</Link>
                  </>
                ) : (
                  <>
                    Not a Brand?{" "}
                    <Link to="/sign-up/influencer">Sign up as an Influencer</Link>
                  </>
                )}
              </p>
            </div>
          </div>
        )}
      </>
    );
  };
  
  const OtpPage = ({ onClick, role }) => {
    const dispatch = useDispatch();
    const [otp, setOtp] = useState(Array.from({ length: 6 }, () => ""));
    const isLoading = useSelector((state) => state.user.loading);
    const { user } = useSelector((state) => state.user);
    const navigate = useNavigate();
    const { userEmail } = useSelector((state) => state.email);
    const email=userEmail&&userEmail.email;
  
    const handleVerifyOTP = () => {
      dispatch(verifyPhoneOTP({ otp,email }));
    };
  
    useEffect(() => {
      if (user.isRegisterSuccess) {
        navigate("/home");
      }
    });
  
    return (
      <>
        {isLoading ? (
          <Loader />
        ) : (
          <div className="login-page">
            <div className="logo">
              {role === "influencer" ? (
                <>
                  <img src={UserIcon} alt="User Logo" />
                  <span className="brand-text">Influencer</span>
                </>
              ) : (
                <>
                  <img src={Badge} alt="Logo" />
                  <span className="brand-text">Brand</span>
                </>
              )}
            </div>
  
            <div className="brand-header-text">
              <h1>Hi, savvy </h1>
              <h1>marketer!</h1>
            </div>
            <div className="phone-login">
              <div>
                <MuiOtpInput
                  value={otp}
                  onChange={setOtp}
                  length={6}
                  separator={<span>-</span>}
                  isInputNum
                />
              </div>
              <div className="user-pref-box">
                <div className="signup-check-box">
                  <input className="signup-checkbox" type="checkbox"></input>
                  <label>Remember Me</label>
                </div>
  
                <p onClick={() => onClick("B")} className="change-phone-number">
                  Change Phone No.
                </p>
              </div>
  
              <button id="generate-otp-button" onClick={handleVerifyOTP}>
                Validate and Sign Up
              </button>
            </div>
  
            <div className="bottom-text">
              <p>
                Already Have an Account? <a href="/login">Log In Here</a>
              </p>
              <p>
                {role === "influencer" ? (
                  <>
                    Not an Influencer?{" "}
                    <Link to="/signup-brand">Sign up as a Brand</Link>
                  </>
                ) : (
                  <>
                    Not a Brand?{" "}
                    <Link to="/signup-influencer">Sign up as an Influencer</Link>
                  </>
                )}
              </p>
            </div>
          </div>
        )}
      </>
    );
  };

function Phone_Sign_Up() {
    const [currentComponent, setCurrentComponent] = useState("B");
    const { role } = useParams();
    const [userEmail, setUserEmail] = useState("");
    const dispatch=useDispatch()

    const getUser = async () => {
        try {
          const url = `http://localhost:5000/auth/login/success`;
          const { data } = await axios.get(url, { withCredentials: true });
          console.log("data of user is",data.user.emails[0].value);
          const email = data.user.emails[0].value;
          if (data && data.status === "success") {
            dispatch(setEmailInRedux({email}))          }
        } catch (err) {
          console.log(err);
        }
      };

    useEffect(()=>{
    getUser();
    })
  
    const handleButtonClick = (component,email = "") => {
      setCurrentComponent(component);
      setUserEmail(email);
    };
  
    return (
      <div className="home-div">
        {currentComponent === "B" && (
          <PhoneDetails role={role} onClick={handleButtonClick} />
        )}
        {currentComponent === "C" && (
          <OtpPage role={role} onClick={handleButtonClick} />
        )}
      </div>
    );
}

export default Phone_Sign_Up