import React from "react";
import "./InfluencerStats.scss";
import Sampleimg from "../assets/sampleimg.png";
import Mailbox from "../assets/mailbox.svg";
import LocationSVG from "../assets/LocationSVG.svg";
import YoutubeButton from "../assets/youtubeButton.svg";
import InstagramIcon from "../assets/instagramICON.svg";
import BackVector from "../assets/BackVector.svg";
import InstagramAccordion from "./InstaAccordion";
import YoutubeAccordion from "./YoutubeAccordion";

const InfluencerStats = () => {
  return (
    <div className="influencer-stats">
      <button>
        <img src={BackVector} alt="back" />
        back
      </button>
      <div className="stat-item">
        <div className="contact">
          <p>Contact</p>
          <div className="contact-details">
            <div className="sub-div">
              <img src={Mailbox} alt="mail" />
              <small> johnmorgan@gmail.com</small>
            </div>
            <div className="sub-div">
              <img src={LocationSVG} alt="location" />
              <small> Andheri west, Mumbai</small>
            </div>
          </div>
        </div>
        <div className="profile">
          <h3>Cardi B</h3>
          <div className="image-box">
            <img src={Sampleimg} alt="img" />
          </div>
          <p>Fashion</p>
        </div>
        <div className="contact">
          <p>Socials</p>
          <div className="contact-details">
            <div className="sub-div">
              <img src={InstagramIcon} alt="instagram" />
              <small>john_morgan</small>
            </div>
            <div className="sub-div">
              <img src={YoutubeButton} alt="youtube" className="youtube" />
              <small> www.youtube.com/@realtalkclipp</small>
            </div>
          </div>
        </div>
      </div>

      <InstagramAccordion />

      <YoutubeAccordion />
    </div>
  );
};

export default InfluencerStats;
