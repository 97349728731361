// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.stepper-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}
.stepper-container .stepper-step {
  flex: 1 1;
  text-align: center;
  position: relative;
  padding: 10px;
  cursor: pointer;
  font-family: poppins;
  font-size: 1.2rem;
  color: var(--primary-200, #ECA3B3);
}
.stepper-container .stepper-step.active {
  color: #D72756;
}
.stepper-container .stepper-step.active .stepper-line {
  background-color: #D72756;
}
.stepper-container .stepper-step.completed {
  color: #D72756;
}
.stepper-container .stepper-step.completed .stepper-line {
  background-color: #D72756;
}
.stepper-container .stepper-step .stepper-line {
  width: 100%;
  height: 2px;
  background-color: #ccc;
  margin-top: 1.2rem;
  bottom: 0;
}`, "",{"version":3,"sources":["webpack://./src/components/Campaign/Stepper.scss"],"names":[],"mappings":"AAEA;EACI,aAAA;EACA,8BAAA;EACA,mBAAA;AADJ;AAGI;EACE,SAAA;EACA,kBAAA;EACA,kBAAA;EACA,aAAA;EACA,eAAA;EACA,oBAAA;EACA,iBAAA;EACA,kCAAA;AADN;AAGM;EACE,cAAA;AADR;AAGQ;EACE,yBAAA;AADV;AAKM;EACE,cAAA;AAHR;AAKQ;EACE,yBAAA;AAHV;AAOM;EAEE,WAAA;EACA,WAAA;EACA,sBAAA;EACA,kBAAA;EACA,SAAA;AANR","sourcesContent":["\n\n.stepper-container {\n    display: flex;\n    justify-content: space-between;\n    margin-bottom: 20px;\n  \n    .stepper-step {\n      flex: 1;\n      text-align: center;\n      position: relative;\n      padding: 10px;\n      cursor: pointer;\n      font-family: poppins;\n      font-size: 1.2rem;\n      color: var(--primary-200, #ECA3B3);\n  \n      &.active {\n        color: #D72756; \n  \n        .stepper-line {\n          background-color: #D72756; \n        }\n      }\n  \n      &.completed {\n        color: #D72756; \n  \n        .stepper-line {\n          background-color: #D72756; \n        }\n      }\n  \n      .stepper-line {\n       \n        width: 100%;\n        height: 2px;\n        background-color: #ccc;\n        margin-top: 1.2rem; \n        bottom: 0; \n      }\n    }\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
