import { configureStore ,combineReducers } from "@reduxjs/toolkit";
import { thunk } from 'redux-thunk';
import { emailReducer, userReducer } from "./reducers/userReducer";
import { campaignReducer, getAllBrandCampaignsReducer,makeCampaignRequestByInfluencerReducer, acceptCampaignRequestByBrandReducer,getCampaignRequestsForBrandReducer, saveCampaignReducer } from "./reducers/campaignReducer";
import { brandProfileReducer, uploadProfilePhotoReducer } from "./reducers/profileReducer";
import { influencersReducer } from "./reducers/influencersReducer";

const reducer = combineReducers({
   user:userReducer,
   email: emailReducer,
   campaign:campaignReducer,
   profile:brandProfileReducer,
   profielPhoto:uploadProfilePhotoReducer,
   campaigns:getAllBrandCampaignsReducer,
   influencers:influencersReducer,
   influencerCampaignRequest:makeCampaignRequestByInfluencerReducer,
   brandCampaignAcceptance:acceptCampaignRequestByBrandReducer,
   brandCampaignRequests:getCampaignRequestsForBrandReducer,
   save:saveCampaignReducer
});

let initialState = {
};

const store = configureStore({
  reducer,
  preloadedState: initialState,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(thunk),
  devTools: true,
});

export default store;