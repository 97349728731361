// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.chats-container {
  display: flex;
  padding: 1rem;
  background-color: var(--Bg, #f9f5f6);
}
.chats-container .conversations-container {
  flex: 1 1;
  min-height: 100vh;
  background-color: transparent;
  margin-right: 0.5rem;
}
.chats-container .messages-container {
  flex: 2 1;
  min-height: 100vh;
  background-color: transparent;
  margin-left: 0.5rem;
}`, "",{"version":3,"sources":["webpack://./src/components/Chats/Chats.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,aAAA;EACA,oCAAA;AACJ;AACI;EACE,SAAA;EACA,iBAAA;EACA,6BAAA;EACA,oBAAA;AACN;AAEI;EACE,SAAA;EACA,iBAAA;EACA,6BAAA;EACA,mBAAA;AAAN","sourcesContent":[".chats-container {\n    display: flex;\n    padding: 1rem;\n    background-color: var(--Bg, #f9f5f6); \n\n    .conversations-container {\n      flex: 1;\n      min-height: 100vh;\n      background-color: transparent;\n      margin-right: 0.5rem; \n    }\n    \n    .messages-container {\n      flex: 2;\n      min-height: 100vh;\n      background-color: transparent;\n      margin-left: 0.5rem; \n    }\n    \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
