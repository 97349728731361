import { Chart } from 'chart.js';
import React, { useEffect, useRef } from 'react'

const HorizontelBarChart = ({ data }) => {
  const chartContainer = useRef(null);
  const maxValue = data.reduce((max, data) => (data.value > max ? data.value : max), data[0].value);
  
  useEffect(() => {
    if (chartContainer && chartContainer.current) {
      const ctx = chartContainer.current.getContext('2d');

      new Chart(ctx, {
        type: 'bar',
        data: {
          labels: data.map(entry => entry.name),
          datasets: [{
            data: data.map(entry => entry.value),
            backgroundColor: data.map(entry => entry.fill),
            barThickness: 25,
          }
          ],
        },
        options: {
          indexAxis: 'y',

          elements: {
            bar: {
              borderWidth: 2,
            }
          },
          responsive: true,
          plugins: {
            legend: {
                display: false,
            },
          },
          scales: {
            x:{
              max: maxValue + 500,
            }
          }
        },
      });
    }
  }, [data]);


  return (
    <div style={{ width: '100%', height: '95%' }}>
      <canvas ref={chartContainer} style={{ width: '100%', height: '90%' }}/>
    </div>
  )
}

export default HorizontelBarChart
