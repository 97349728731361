export const CREATE_BRAND_PROFILE_REQUEST = "CREATE_BRAND_PROFILE_REQUEST";
export const CREATE_BRAND_PROFILE_SUCCESS = "CREATE_BRAND_PROFILE_SUCCESS";
export const CREATE_BRAND_PROFILE_FAIL = "CREATE_BRAND_PROFILE_FAIL";

export const UPDATE_PROFILE_PHOTO_REQUEST = "UPDATE_PROFILE_PHOTO_REQUEST";
export const UPDATE_PROFILE_PHOTO_SUCCESS = "UPDATE_PROFILE_PHOTO_SUCCESS";
export const UPDATE_PROFILE_PHOTO_FAIL = "UPDATE_PROFILE_PHOTO_FAIL";

export const GET_BRAND_PROFILE_REQUEST = "GET_BRAND_PROFILE_REQUEST";
export const GET_BRAND_PROFILE_SUCCESS = "GET_BRAND_PROFILE_SUCCESS";
export const GET_BRAND_PROFILE_FAIL = "GET_BRAND_PROFILE_FAIL";

export const GET_BRAND_PROFILE_INFO_REQUEST="GET_BRAND_PROFILE_INFO_REQUEST";
export const GET_BRAND_PROFILE_INFO_SUCCESS="GET_BRAND_PROFILE_INFO_SUCCESS";
export const GET_BRAND_PROFILE_INFO_FAIL="GET_BRAND_PROFILE_INFO_FAIL";

export const GET_BRAND_CONTACT_INFO_REQUEST="GET_BRAND_CONTACT_INFO_REQUEST";
export const GET_BRAND_CONTACT_INFO_SUCCESS="GET_BRAND_CONTACT_INFO_SUCCESS";   
export const GET_BRAND_CONTACT_INFO_FAIL="GET_BRAND_CONTACT_INFO_FAIL";

export const UPDATE_BRAND_PROFILE_INFO_REQUEST = "UPDATE_BRAND_PROFILE_INFO_REQUEST";
export const UPDATE_BRAND_PROFILE_INFO_SUCCESS = "UPDATE_BRAND_PROFILE_INFO_SUCCESS";
export const UPDATE_BRAND_PROFILE_INFO_FAIL = "UPDATE_BRAND_PROFILE_INFO_FAIL";
export const UPDATE_BRAND_PROFILE_INFO_RESET = "UPDATE_BRAND_PROFILE_INFO_RESET";

export const UPDATE_BRAND_CONTACT_INFO_REQUEST = "UPDATE_BRAND_CONTACT_INFO_REQUEST";
export const UPDATE_BRAND_CONTACT_INFO_SUCCESS = "UPDATE_BRAND_CONTACT_INFO_SUCCESS";
export const UPDATE_BRAND_CONTACT_INFO_FAIL = "UPDATE_BRAND_CONTACT_INFO_FAIL";
export const UPDATE_BRAND_CONTACT_INFO_RESET = "UPDATE_BRAND_CONTACT_INFO_RESET";

export const GET_PROFILE_DETAILS_FOR_BRAND_REQUEST = "GET_PROFILE_DETAILS_FOR_BRAND_REQUEST";
export const GET_PROFILE_DETAILS_FOR_BRAND_SUCCESS = "GET_PROFILE_DETAILS_FOR_BRAND_SUCCESS";
export const GET_PROFILE_DETAILS_FOR_BRAND_FAIL = "GET_PROFILE_DETAILS_FOR_BRAND_FAIL";


